import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomDateAdapter } from 'src/app/adapters/customdate.adapter';
import { Agency } from 'src/app/models/Agency';
import { CardMovement } from 'src/app/models/CardMovement';
import { DataService } from 'src/app/services/data.service';
import moment from 'moment';
import { AutocompleteField, OptionColumns as ChipsOptionColumns } from 'src/app/components/forms/autocomplete-field/autocomplete-field.component';
import { Observable } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';
import * as _ from "lodash";
import { CityDialog } from 'src/app/components/dialogs/citydialog/city-dialog.component';
import { City } from 'src/app/models/City';
import { AjaxAutocompleteChipsInput } from 'src/app/components/forms/ajax-autocomplete-chips/ajax-autocomplete-chips.component';
import { BespokeDialog } from '../bespokedialog/bespoke-dialog.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSelect } from '@angular/material/select';
import { ConfirmDialog } from 'src/app/components/dialogs/confirmdialog/confirm-dialog.component';

@Component({
  selector: 'movement-dialog',
  templateUrl: './movement-dialog.component.html',
  styleUrls: ['./movement-dialog.component.scss'],
  providers: [
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.

    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class MovementDialog {
  private segment_media_id = 1;
  private media_all_id = 1;
  private assignemnt_buy_id = 2;
  private assignemnt_plan_id = 1;
  protected server_errors = null;
  public ready = false;
  public btn_save_loader = false;
  public btn_save_disabled = false;
  public save_clicked: boolean = false;  
  public form: UntypedFormGroup;
  public previousMovement = null;
  public cardMovements = null;
  public showAgencyInfo: boolean = false;
  public showAgencyCity : boolean = false;
  public showBespokeInput = false;
  public agencyExtraParameters = null;
  public card = null;
  public textareaMinRows: number = 4;
  public textareaMaxRows: number = 8;
  public show_retained_msg = false;
  public advertiser = null;
  public selectedBespokeObject = null;

  public mode = null;
  public movement = null;

  public selectedAgency = null;
  public movementAgency = null;
  public agencyLoader = false;

  public movementsBespokes = [];

  public selectedPitch = null;
  public showPitchPredictor = false;
  public predictorInnerHTML = null;
  public predictorOptions = ['auto', 75, 50, 0];
  public isForced = false;

  public transferCards = false;
  public agency = null;
  public allCountriesOption = {
    id: 'all',
    label: this.translate.instant('NBB.NEW_REPORT_DIALOG.ALL_COUNTRIES'),
    removable: false
  }
  public card_count = 0;

  public assignmentsOptionsColumns: ChipsOptionColumns = {
    id: 'id',
    label: 'acronym',
    operator: 'start'
  };
  public mediaOptionsColumns: ChipsOptionColumns = {
    id: 'id',
    label: 'name'
  };
  public consultantsOptionsColumns: ChipsOptionColumns = {
    id: 'id',
    label: 'name',
    operator: 'start'
  };
  public contendersOptionsColumns: ChipsOptionColumns = {
    id: 'id',
    label: 'name',
    operator: 'start'
  };

  protected tabs_fields = {
    'assignments': ['medias', 'assignments'],
    'in_review': ['inReviewAt'],
    'incumbent': ['movedAt', 'effectiveAt', 'agency_id', 'zoneCoverage']
  }

  public countryExtraParameters = {
    filters:
      [
        { column: 'thresholdAmount', operator: '!=', value: 0 },
        { column: 'thresholdAmount', operator: '!=', value: null },
      ]
  }

  @ViewChild('cities', { static: false }) cities: AjaxAutocompleteChipsInput;
  @ViewChild('matselect', { static: false }) matselect: MatSelect;
  @ViewChild('agency', { static: false }) agency_autocomplete: AutocompleteField;
  @ViewChild('countries', { static: false }) countries: AjaxAutocompleteChipsInput;

  constructor(
    public dialogRef: MatDialogRef<MovementDialog>,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data, private dataService: DataService,
    public dialog: MatDialog) {

    this.form = new UntypedFormGroup({
      card_id: new UntypedFormControl('', [Validators.required]),
      // card_id: new UntypedFormControl('', []),
      agency_id: new UntypedFormControl('', []),
      cities: new UntypedFormControl('', []),
      agency_network_id: new UntypedFormControl('', []),
      agency_group_id: new UntypedFormControl('', []),
      agency_holding_id: new UntypedFormControl('', []),
      bespoke_id: new UntypedFormControl('', []),
      zoneCoverage: new UntypedFormControl('', [Validators.required]),
      zoneDetails: new UntypedFormControl('', []),
      isFollowingPitch: new UntypedFormControl(true, []),
      inReviewAt: new UntypedFormControl('', [this.inReviewAtValidation.bind(this)]),
      type: new UntypedFormControl('agency', [this.typeAtValidation.bind(this)]),
      movedAt: new UntypedFormControl('', [this.movedAtValidation.bind(this)]),
      unknownMovedAt: new UntypedFormControl(false, []),
      effectiveAt: new UntypedFormControl('', [this.effectiveAtValidation.bind(this)]),
      consultants: new UntypedFormControl('', []),
      comment_consultants: new UntypedFormControl('', []),
      contenders: new UntypedFormControl('', []),
      comment_contenders: new UntypedFormControl('', []),
      resignedAt: new UntypedFormControl(null, []),
      contractDuration: new UntypedFormControl(null, []),
      contractEndsAt: new UntypedFormControl(null, []),
      medias: new UntypedFormControl('', [Validators.required]),
      assignments: new UntypedFormControl('', [Validators.required]),
      pitch_predictor:new UntypedFormControl(null, []),
      pitch_predictor_comment:new UntypedFormControl(null,[]),
      isConfidential: new UntypedFormControl(false, []),
      countries: new UntypedFormControl(this.allCountriesOption, []),
      source: new UntypedFormControl(null, []),
    }, {
      updateOn: 'change',
    })

    this.filteredBespokes = this.bespokeCtrl.valueChanges.pipe(
      startWith(null),
      map((bespoke: string | null) => bespoke ? this._filter(bespoke) : this.movementsBespokes.slice()));

    // console.log("dialog data = ", data);

    // change type if select date review
    this.form.get('inReviewAt').valueChanges.pipe(first()).subscribe(v => {
      if (this.mode == 'new') {
        let event: { value: string } = {
          value: "unknown"
        }
        this.form.get('type').setValue('unknown');
        this.onTypeChange(event);
      }
    })

    this.form.get('inReviewAt').valueChanges.subscribe(v => {
      this.form.get('movedAt').updateValueAndValidity({ onlySelf: true, emitEvent: false });
      this.form.get('effectiveAt').updateValueAndValidity({ onlySelf: true, emitEvent: false });
    })

    let filtered_movements = [];
    this.form.get('movedAt').valueChanges.subscribe(v => {
      this.form.get('inReviewAt').updateValueAndValidity({ onlySelf: true, emitEvent: false });
      this.form.get('effectiveAt').updateValueAndValidity({ onlySelf: true, emitEvent: false });

      if (this.form.value.type == 'retained') {
        filtered_movements = _.filter(
          this.cardMovements, function (m) {
            return m.movedAt < v.format('YYYY-MM-DD');
          }
        );
        if (filtered_movements.length) {
          this.previousMovement = filtered_movements[0];
        }
        if (this.form.value.type) {
          this.onTypeChange({ value: this.form.value.type });
        }
      }
    })
    this.form.get('effectiveAt').valueChanges.subscribe(v => {
      this.form.get('inReviewAt').updateValueAndValidity({ onlySelf: true, emitEvent: false });
      this.form.get('movedAt').updateValueAndValidity({ onlySelf: true, emitEvent: false });
    })

    // change type if select date review
    this.form.get('unknownMovedAt').valueChanges.subscribe(v => {
      if(v) {
        this.form.controls['movedAt'].disable();
        this.form.controls['effectiveAt'].disable();
        this.form.controls['movedAt'].patchValue(null);
        this.form.controls['effectiveAt'].patchValue(null);
      } else {
        this.form.controls['movedAt'].enable();
        this.form.controls['effectiveAt'].enable();
      }
    })

    // bespoke_id validation
    this.bespokeCtrl.addValidators([this.hasBespokeError.bind(this)])
  }

  ngOnInit() {
    let proms = [];
    if (this.data) {
      // card
      if (this.data.card) {
        this.card = this.data.card;
      }
      // advertiser
      if(this.data.advertiser) {
        this.advertiser = this.data.advertiser;
      }
      
      if(this.data.isLast){
        this.showPitchPredictor=true;
      }

      // previousMovement
      this.previousMovement = this.data.previous_movement ? this.data.previous_movement : null;
      this.cardMovements = this.data.card_moovements ? this.data.card_moovements : null;

      if (this.data.item) {
        this.mode = 'update';
        // proms.push(
        // this.dataService.getAsPromise(`admin/card_movements/${this.data.item.id}`).then(res => {
        //   if(res && res.data) {
        //     this.movement = new CardMovement(res.data);

        this.movement = new CardMovement(this.data.item);

        this.form.patchValue(this.movement);

        if (this.movement.inReviewAt && this.movement.inReviewAt == '0000-00-00') {
          this.form.get('inReviewAt').patchValue(null);
        }
        if ((this.movement.effectiveAt && this.movement.effectiveAt == '0000-00-00') || this.movement.unknownMovedAt) {
          this.form.get('effectiveAt').patchValue(null);
        }
        if ((this.movement.movedAt && this.movement.movedAt == '0000-00-00') || this.movement.unknownMovedAt) {
          this.form.get('movedAt').patchValue(null);
        }

        if (this.form.controls.movedAt.value && !this.form.controls.effectiveAt.value) {
          this.changeMovedAtDate(this.form.controls.movedAt.value);
        }

        this.showMessageTypeRetained(this.movement.type, this.movement.isFollowingPitch)

        if (this.movement.medias) {
          let medias = [];
          this.movement.medias.forEach(m => {
            medias.push({
              id: m.id,
              label: m.name || m.label
            })
          })
          this.form.get('medias').patchValue(medias);
        }

        if (this.movement.assignments) {
          let assignments = [];
          this.movement.assignments.forEach(a => {
            assignments.push({
              id: a.id,
              label: a.acronym || a.label
            })
          })
          this.form.get('assignments').patchValue(assignments);
        }

        if (this.movement.cities) {
          let cities = [];
          this.movement.cities.forEach(c => {
            cities.push({
              id: c.id,
              label: c.name || c.label
            })
          })
          this.form.get('cities').patchValue(cities);
        }

        if (this.movement.movedAt == '?') {
          this.form.get('movedAt').patchValue('');
        }

        if (this.movement.agency) {
          let agency = this.movement.agency;
          this.selectedAgency = agency;
          this.movementAgency = agency;
          this.form.get('agency_id').patchValue({
            id: agency.id,
            label: agency.name
          });

          if (agency.agency_network) {
            let agency_network = agency.agency_network;
            this.form.get('agency_network_id').patchValue(agency_network.id);

            if (agency_network.group) {
              let group = agency_network.group;
              this.form.get('agency_group_id').patchValue(group.id);

              if (group.holding) {
                let holding = group.holding;
                this.form.get('agency_holding_id').patchValue(holding.id);
              }
            }
          }
        }

        if (this.movement.consultants) {
          let consultants = [];
          this.movement.consultants.forEach(c => {
            let c_label = c.name || c.label;
            consultants.push({
              id: c.id,
              label: c_label
            })
            this.consultantsNames.push(c_label);
          })
          this.form.get('consultants').patchValue(consultants);
        }

        if (this.movement.contenders) {
          let contenders = [];
          this.movement.contenders.forEach(c => {
            let c_label = c.name || c.label;
            contenders.push({
              id: c.id,
              label: c_label
            })
            this.contendersNames.push(c_label);
          })
          this.form.get('contenders').patchValue(contenders);
        }

        // }
        // })
        // );
        let agency_id = this.movement.agency_id;
        if (agency_id) {
          proms.push(
            this.loadBespokes(agency_id)
          );
        }


        //pitch_predictor
        //if there is already a forced_pitch_predictor value use it
        if(this.movement.pitch_predictor!==null){
          this.onPitchPredictorChange({value:this.movement.pitch_predictor});
        }
        else{
          //else calculate the value and set the select to auto       
          this.onPitchPredictorChange({value:'auto'});
        }
          
      } else {
        this.mode = 'new';
        // this.previousMovement = this.data.previous_movement ? this.data.previous_movement : null;

        if (!this.previousMovement && this.card && this.card.segment && this.card.segment.id === this.segment_media_id) {
          this.form.get('medias').patchValue(this.media_all_id)
          this.form.get('assignments').patchValue([this.assignemnt_buy_id, this.assignemnt_plan_id])
        }

        //pitch predictor is zero and set the select to auto       
        this.onPitchPredictorChange({value:'auto'});
      }

      if (this.data.card_id) {
        this.form.get('card_id').patchValue(this.data.card_id);

        // set Media and Comv assignment from the previous movement
        if (this.mode == 'new' && this.previousMovement) {
          let prevMedias = [];
          let prevAssignments = [];
          if (this.previousMovement.medias) {
            this.previousMovement.medias.forEach(m => {
              prevMedias.push({
                id: m.id,
                label: m.name || m.label
              });
            });
            this.form.get('medias').patchValue(prevMedias);
          }
          if (this.previousMovement.assignments) {
            this.previousMovement.assignments.forEach(a => {
              prevAssignments.push({
                id: a.id,
                label: a.acronym || a.label
              });
            });
            this.form.get('assignments').patchValue(prevAssignments);
          }
        }
      }

      if(this.data.agency){
        this.agency = this.data.agency;
      }
    }

    Promise.all(proms).then(res => {
      // set bespoke_id
      if(this.movement && this.movement.bespoke_id) {
        let o = _.find(this.movementsBespokes, {id: this.movement.bespoke_id});
        if(o) {
          this.bespokeUnit = o;
        } else if(this.movement && this.movement.bespoke) {
          this.bespokeUnit = {
            id: this.movement.bespoke.id,
            name: this.movement.bespoke.name
          }
        }
        this.selectedBespokeObject = this.movement.bespoke;
      }

      if (this.form.value.type === 'agency' || this.form.value.type === 'transfer' || this.form.value.type === 'retained' || this.form.value.type === 'new-assignment' || this.form.value.type === 'new-owner' || this.form.value.type === 'contract-renewal') {
        if (this.form.value.type === 'transfer' && this.previousMovement && this.previousMovement.agency) {
          if (this.previousMovement.agency.agency_network && this.previousMovement.agency.agency_network.group) {
            this.agencyExtraParameters = {
              filters:
                [
                  {
                    operator: 'join',
                    joins: [
                      {
                        table: 'agency_networks',
                        on: ['agencies.agency_network_id', 'agency_networks.id']
                      },
                      {
                        table: 'groups',
                        on: ['agency_networks.group_id', 'groups.id']
                      }
                    ]
                  },
                  {
                    column: 'groups.id', operator: '=', value: this.previousMovement.agency.agency_network.group.id
                  }
                ]
            }
          }
        }
        this.showAgencyInfo = true;
        this.showAgencyCity = true;

        this.form.controls['agency_id'].setValidators([Validators.required]);
        this.form.controls['agency_id'].updateValueAndValidity();

      } else if(this.form.value.type === 'in-house') {     
        this.showAgencyCity = true;
      } else {
        this.form.controls['agency_id'].setValidators(null);
        this.form.controls['agency_id'].updateValueAndValidity();
      }

      if (this.form.value.type === 'unknown') {
        this.form.get('assignments').setValidators([]);
        this.form.get('medias').setValidators([]);
      } else {
        this.form.get('assignments').setValidators([Validators.required]);
        this.form.get('medias').setValidators([Validators.required]);
      }

      if(this.data.transferCards){
        this.transferCards = true;
        //move type is transfer
        this.onTypeChange({value:'transfer'});
        this.tabs_fields.incumbent = ['movedAt', 'effectiveAt', 'agency_id'];
        this.card_count = this.data.card_count;
      }

      this.ready = true;

      if(this.mode == 'new') {
        // if first movement, set cities = card country capital
        if(!this.previousMovement) {
          if(this.card && this.card.country) {
            this.form.get('cities').patchValue([this.card.country.capital_id]);
            if(this.cities) {
              this.cities.loadField(); 
            }
          }
        } else {
          let cities = [];
          let prevCities = this.previousMovement.cities;
          if(prevCities) {
            prevCities.forEach(c => {
              cities.push({
                id: c.id,
                label: c.name || c.label
              })
            })
          }
          this.form.get('cities').patchValue(cities);
          if(this.cities) {
            this.cities.loadField();
          }
        }
      }

      // console.log(this.movement);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setData() {
    let formData = this.form.getRawValue();

    // format date fields
    if (formData['inReviewAt'] && formData['inReviewAt'] != '' && typeof (formData['inReviewAt']) == 'object') {
      formData['inReviewAt'] = formData['inReviewAt'].format('YYYY-MM-DD');
    } else if (formData['inReviewAt'] == '') {
      formData['inReviewAt'] = null;
    }
    if (formData['effectiveAt'] && formData['effectiveAt'] != '' && typeof (formData['effectiveAt']) == 'object') {
      formData['effectiveAt'] = formData['effectiveAt'].format('YYYY-MM-DD');
    } else if (formData['effectiveAt'] == '') {
      formData['effectiveAt'] = null;
    }
    if (formData['movedAt'] && formData['movedAt'] != '' && typeof (formData['movedAt']) == 'object') {
      formData['movedAt'] = formData['movedAt'].format('YYYY-MM-DD');
    } else if (formData['movedAt'] == '') {
      formData['movedAt'] = null;
    }

    // unknown moved at
    if(formData['unknownMovedAt']) {
      formData['movedAt'] = '1970-01-01';
      formData['effectiveAt'] = '1970-01-01';
    }

    if (this.movementAgency) {
      formData['agency'] = this.movementAgency;
    }

    if (this.consultantsNames.length) {
      formData['consultants_names'] = this.consultantsNames.join(', ');
    }

    if (this.contendersNames.length) {
      formData['contenders_names'] = this.contendersNames.join(', ');
    }

    let medias = this.form.value.medias;
    if (medias && medias.length) {
      let mediasNames = [];
      medias.forEach(m => {
        mediasNames.push(m.label);
      });
      formData['medias_names'] = mediasNames.join(', ');
    }

    let assignments = this.form.value.assignments;
    if (assignments && assignments.length) {
      let assignmentsNames = [];
      assignments.forEach(a => {
        assignmentsNames.push(a.label);
      });
      formData['assignments_names'] = assignmentsNames.join(', ');
    }

    let cities = this.form.value.cities;
    if (cities && cities.length) {
      let citiesNames = [];
      cities.forEach(m => {
        citiesNames.push(m.label);
      });
      formData['cities_names'] = citiesNames.join(', ');
    }

    if (this.movement) {
      formData['id'] = this.movement.id;
    }

    if (this.bespokeUnit != null) {
      formData['bespoke_id'] = this.bespokeUnit.id;
    } else if (this.bespokeCtrl.value != null) {
      let filterValue = null;
      if(this.bespokeCtrl.value.id ?? false) {
        formData['bespoke_id'] = this.bespokeCtrl.value.id;
      } else {
        formData['bespoke_id'] = this.bespokeCtrl.value;
      }
    } else {
      formData['bespoke_id'] = null;
    }

    if (formData['type'] !== 'agency' && formData['type'] !== 'transfer' && formData['type'] !== 'retained' && formData['type'] !== 'new-assignment' && formData['type'] !== 'new-owner' && formData['type'] !== 'contract-renewal') {
      if(formData['type'] === 'in-house'){
        formData['agency_id'] = null;
      }else{
        formData['agency_id'] = null;
        formData['bespoke_id'] = null;
      }
    }

    if(formData['pitch_predictor'] == 'auto'){
      formData['pitch_predictor'] = null;
    }

    return formData;
  }

  save() {
    if(this.transferCards){
      this.saveTransfer();
    }else{
      this.reValidateForms();
      this.btn_save_disabled = true;
      this.btn_save_loader = true;
      this.save_clicked = true;
  
      let data = this.setData();
      data['bespoke'] = this.selectedBespokeObject;
      // console.log("SAVE DATA = ", data);
      // console.log("FORM = ", this.form);
      if(!this.showIsConfidential()) {
        data['isConfidential'] = false;
      }
  
      if (this.form.valid && this.bespokeCtrl.valid) {
        if (!this.movement) {
          this.create(data);
        } else if (this.movement) {
          this.update(data);
        }
      } else {
        console.log("Invalid form!")
        this.btn_save_disabled = false;
        this.btn_save_loader = false;
      }
    }  
  }

  create(data) {
    this.movement = new CardMovement(data);
    // console.log(this.movement);
    this.dialogRef.close({ item: this.movement, mode: 'new' });
  }

  update(data) {
    //set updated to true for recently updated at feature
    data['updated'] = true;
    this.movement = new CardMovement(data);
    // console.log(this.movement);
    this.dialogRef.close({ item: this.movement, mode: 'edit' });
  }

  hasError(controlName: string, errorName: string) {
    if (!controlName) {
      return this.form.hasError(errorName);
    }
    return this.form.controls[controlName].hasError(errorName);
  }

  reValidateForms() {
    this.form.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    for (let f in this.form.controls) {
      this.form.controls[f].markAsTouched();
      this.form.controls[f].updateValueAndValidity({ emitEvent: false });
    }
  }

  inReviewAtValidation(control: AbstractControl) {
    const parent = control["_parent"];
    if (parent) {
      if (!control.value && !parent.controls['movedAt'].value && !parent.controls['effectiveAt'].value && !parent.controls['unknownMovedAt'].value) {
        return { inReviewAtRequired: true };
      }
    }
    return null;
  }

  movedAtValidation(control: AbstractControl) {
    const parent = control["_parent"];
    if (parent) {
      let controlValue = control.value;
      if(control.value && control.value != '' && typeof (control.value) == 'object') {
        controlValue = controlValue.format('YYYY-MM-DD');
      }
      
      if(!control.value && parent.controls['type'].value && parent.controls['type'].value == 'retained' && !parent.controls['unknownMovedAt'].value) {
        return { movedAtRequired: true };
      } else if (!control.value && !parent.controls['inReviewAt'].value && !parent.controls['effectiveAt'].value && !parent.controls['unknownMovedAt'].value) {
        return { movedAtRequired: true };
      } else if(control.value && controlValue == '1970-01-01') {
        return { movedAtRequired: true };
      }
    }
    return null;
  }

  effectiveAtValidation(control: AbstractControl) {
    const parent = control["_parent"];
    if (parent) {
      let controlValue = control.value;
      if(control.value && control.value != '' && typeof (control.value) == 'object') {
        controlValue = controlValue.format('YYYY-MM-DD');
      }

      if (!control.value && !parent.controls['movedAt'].value && !parent.controls['inReviewAt'].value && !parent.controls['unknownMovedAt'].value) {
        return { effectiveAtRequired: true };
      } else if(control.value && control.value == '1970-01-01') { 
        return { effectiveAtRequired: true };
      } else if(control.value && controlValue == '1970-01-01') {
        return { effectiveAtRequired: true };
      }
    }
    return null;
  }

  typeAtValidation(control: AbstractControl) {
    const parent = control["_parent"];
    if (parent) {
      if (control.value && control.value == 'agency') {
        if (this.previousMovement && this.previousMovement.agency && this.form.value.agency_id == this.previousMovement.agency.id) {
          return { incorectType: true };
        }
      }
    }
    return null;
  }

  hasBespokeError(control: AbstractControl) {
    if (control.value && typeof control.value == 'string' ) {
      if(this.bespokesList) {
        this.bespokesList.errorState = true;
      }
      return { incorectBespoke: true };
    }
    if(this.bespokesList) {
      this.bespokesList.errorState = false;
    }
    return null;
  }

  onAgencySelect(event) {
    // clear bespokes
    this.clearBespoke();
    this.agencyLoader = true;
    this.bespokeUnitLoader = true;
    this.dataService.getAsPromise(`admin/agencies/${event.id}`).then(res => {
      if (res && res.data) {
        let selectedAgency = res.data;
        this.selectedAgency = new Agency(selectedAgency)
        // console.log("AGENCY = ", this.selectedAgency)
        let movementAgency = {
          id: this.selectedAgency.id,
          name: this.selectedAgency.name
        }
        if (selectedAgency.agency_network) {
          this.form.controls['agency_network_id'].patchValue(selectedAgency.agency_network.id);
          movementAgency['agency_network'] = {
            id: selectedAgency.agency_network.id,
            name: selectedAgency.agency_network.name
          }
          if (selectedAgency.agency_network.group) {
            this.form.controls['agency_group_id'].patchValue(selectedAgency.agency_network.group.id);
            movementAgency['agency_network']['group'] = {
              id: selectedAgency.agency_network.group.id,
              name: selectedAgency.agency_network.group.name
            }
            if (selectedAgency.agency_network.group.holding) {
              this.form.controls['agency_holding_id'].patchValue(selectedAgency.agency_network.group.holding.id);
              movementAgency['agency_network']['group']['holding'] = {
                id: selectedAgency.agency_network.group.holding.id,
                name: selectedAgency.agency_network.group.holding.name
              }
            }
          }
        }
        this.movementAgency = movementAgency;
      }

      this.agencyLoader = false;
      // this.bespokeUnit = null;

      if(!this.transferCards){
        this.loadBespokes(this.selectedAgency.id, true);
      }else{
        this.bespokeUnitLoader = false;
      }
      
      // revalidate type field
      this.form.controls['type'].markAsTouched();
      this.form.controls['type'].updateValueAndValidity({ emitEvent: false });
    })
  }

  public consultantsNames = [];
  onConsultantSelect(event) {
    if (this.consultantsNames.indexOf(event.label) == -1) {
      this.consultantsNames.push(event.label)
    }
  }

  onConsultantRemove(event) {
    let indexOf = this.consultantsNames.indexOf(event.label)
    this.consultantsNames.splice(indexOf, 1);
  }

  public contendersNames = [];
  onContenderSelect(event) {
    if (this.contendersNames.indexOf(event.label) == -1) {
      this.contendersNames.push(event.label)
    }
  }

  onContenderRemove(event) {
    let indexOf = this.contendersNames.indexOf(event.label)
    this.contendersNames.splice(indexOf, 1);
  }

  public mediasNames = [];
  onMediaSelect(event) {
    if (this.mediasNames.indexOf(event.label) == -1) {
      this.mediasNames.push(event.label)
    }
  }

  onMediaRemove(event) {
    let indexOf = this.mediasNames.indexOf(event.label)
    this.mediasNames.splice(indexOf, 1);
  }

  public assignmentsNames = [];
  onAssignmentSelect(event) {
    if (this.assignmentsNames.indexOf(event.label) == -1) {
      this.assignmentsNames.push(event.label)
    }
  }

  onAssignmentRemove(event) {
    let indexOf = this.assignmentsNames.indexOf(event.label)
    this.assignmentsNames.splice(indexOf, 1);
  }

  calcMovedQuarter(val) {
    if (!val) {
      this.form.controls['movedQuarter'].setValue(null);
    } else {
      let date = moment(val);
      let quarter = date.year() + ' Q' + (date.quarter());
      this.form.controls['movedQuarter'].setValue(quarter);
    }
  }

  clearMovedQuarter() {
    this.form.controls['movedAt'].setValue('');
    this.form.controls['movedQuarter'].setValue('');
  }

  // bespokeUnit mat-autocomplete chip
  @ViewChild('bespokesList') bespokesList;
  @ViewChild('bespokeInput') bespokeInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  public bespokeCtrl = new UntypedFormControl();
  public filteredBespokes: Observable<any[]>;
  public bespokeUnit = null;
  public bespokeUnitLoader = false;

  loadBespokes(agency_id, withPreFill = false) {
    this.movementsBespokes = [];
    let params = {}
    if(this.advertiser) {
      params = {
        advertiser_id: this.advertiser.advertiser_id,
        parentco_id: this.advertiser.parentco_id
      }

      return this.dataService.getAsPromise(`admin/card_movements/bespokes/${agency_id}`, {params : params}).then(res => {
        if (res && res.data) {
          res.data.forEach(b => {
            this.movementsBespokes.push(b);
          });
        }
        this.filteredBespokes = this.bespokeCtrl.valueChanges.pipe(
          startWith(null),
          map((bespoke: string | null) => bespoke ? this._filter(bespoke) : this.movementsBespokes.slice())
        );
  
        this.bespokeUnitLoader = false;
  
        // clear bespoke field if existing value is not inside the movementsBespokes
        if(this.bespokeUnit) {
          let o = _.find(this.movementsBespokes, {id: this.bespokeUnit.id});
          if(!o) {
            this.clearBespoke();
          }
        }

        // prefill
        if(withPreFill) {
          if(!this.bespokeUnit) {
            if(this.movementsBespokes.length) {
              let default_option = this.movementsBespokes[0];
              let o = _.find(this.movementsBespokes, {advertiser_id: this.advertiser.advertiser_id, agency_id: agency_id});
              if(o) {
                default_option = o;
              }
              let option = {
                option: {
                  value: default_option
                }
              }
              this.selectedBespoke(option)
            }
          }
        }
      }).catch(err => {
        this.bespokeUnitLoader = false;
      })
    }
  }

  addBespoke(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.bespokeUnit = value.trim();
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.bespokeCtrl.setValue(null);
  }

  removeBespoke(bespoke: string): void {
    this.bespokeUnit = null;
    this.selectedBespokeObject = null;
  }

  selectedBespoke(event: MatAutocompleteSelectedEvent | any): void {
    this.bespokeUnit = event.option.value;

    this.dataService.getAsPromise(`admin/bespokes/${this.bespokeUnit.id}`).then(res => {
      if(res && res.data) {
        let bespoke = res.data;
        this.selectedBespokeObject = {
          id: bespoke.id,
          name: bespoke.name,
          advertiser_id: bespoke.advertiser_id,
          parentco_id: bespoke.parentco_id,
          agency_id: bespoke.agency_id,
          agency_network_id: bespoke.agency_network_id,
          group_id: bespoke.group_id,
          holding_id: bespoke.holding_id
        }
      } else {
        this.selectedBespokeObject = null;
      }
    });

    if (this.bespokeInput) {
      this.bespokeInput.nativeElement.value = '';
      this.bespokeInput.nativeElement.blur()
    }
    this.bespokeCtrl.setValue(null);
  }


  private _filter(value: string | any): string[] {
    let filterValue = null;
    if(value.name ?? false) {
      filterValue = value.name.toLowerCase();
    } else {
      filterValue = value.toLowerCase();
    }

    return this.movementsBespokes.filter(bespoke => bespoke.name.toLowerCase().indexOf(filterValue) !== -1);
  }

  tabError(tab) {
    if (!this.tabs_fields[tab] || !this.save_clicked) {
      return 0;
    }
    let n = 0;
    this.tabs_fields[tab].forEach(f => {
      if (this.form.controls[f] && !this.form.controls[f].valid && !this.form.controls[f].disabled) {
        n++;
      }
    });
    return n;
  }

  onTypeChange(event) {
    this.showAgencyInfo = false;
    this.showAgencyCity = false;
    this.showBespokeInput = false;
    let value = event.value;
    // if(value === 'retained' || value === 'new-assignment' || value === 'new-owner' || value === 'contract-renewal') {
    if (value === 'retained' || value === 'new-assignment' || value === 'contract-renewal') {
      if (this.previousMovement && this.previousMovement.agency) {
        this.setAgency(this.previousMovement, value);
      } else {
        this.clearAgency();
      }
      setTimeout(() => {
        this.showAgencyInfo = true;
        this.showAgencyCity = true;
        this.form.controls['agency_id'].setValidators([Validators.required]);
        this.form.controls['agency_id'].updateValueAndValidity();
      })
    } else if (value === 'transfer') {
      if (this.previousMovement && this.previousMovement.agency) {
        this.setTransferAgency(this.previousMovement, value);
      } else if(this.transferCards){
        setTimeout(()=>{
          this.matselect.value = 'transfer';
          this.clearAgency();
        },100);       
      }else {
        this.clearAgency();
      }
      setTimeout(() => {
        this.showAgencyInfo = true;
        this.showAgencyCity = true;
        this.form.controls['agency_id'].setValidators([Validators.required]);
        this.form.controls['agency_id'].updateValueAndValidity();
      })
    } else if (value === 'agency' || value === 'new-owner') {
      if (this.movement && this.movement.agency) {
        this.setAgency(this.movement, value);
      } else {
        this.clearAgency();
      }
      setTimeout(() => {
        this.showAgencyInfo = true;
        this.showAgencyCity = true;
        this.form.controls['agency_id'].setValidators([Validators.required]);
        this.form.controls['agency_id'].updateValueAndValidity();

      })
    } else if(value === 'in-house') {      
      this.clearAgency();
      setTimeout(() => {
        this.showAgencyCity = true;
        this.form.controls['agency_id'].setValidators(null);
        this.form.controls['agency_id'].updateValueAndValidity();
      })
    } else {
      this.clearAgency();
      this.form.controls['agency_id'].setValidators(null);
      this.form.controls['agency_id'].updateValueAndValidity();
    }

    if(value === 'in-house'){
      this.clearBespoke();
      this.filteredBespokes = null;

      this.showBespokeInput = true;
    }

    if (value === 'unknown' || value === 'new-advertiser') {
      this.form.controls['zoneCoverage'].clearValidators();
      this.form.controls['zoneCoverage'].updateValueAndValidity();
    } else {
      this.form.controls['zoneCoverage'].setValidators([Validators.required]);
      this.form.controls['zoneCoverage'].updateValueAndValidity();
    }

    if (value === 'unknown') {
      this.form.get('assignments').setValidators([]);
      this.form.get('medias').setValidators([]);
      this.form.get('assignments').updateValueAndValidity();
      this.form.get('medias').updateValueAndValidity();
    } else {
      this.form.get('assignments').setValidators([Validators.required]);
      this.form.get('medias').setValidators([Validators.required]);
      this.form.get('assignments').updateValueAndValidity();
      this.form.get('medias').updateValueAndValidity();
    }

    // set isFollowingPitch false
    if (value === 'transfer' || value === 'in-house' || value === 'unknown' || value === 'non-disclosed' || (this.mode == 'new' && value === 'new-advertiser')
    || (value === 'contract-renewal') || (value === 'inactive') || (value === 'left-country') || (value ==='bankrupt') || (value ==='merger')
    || (value == 'new-owner') || (value == 'new-assignment') || (value == 'various')
    ) {
      this.form.get('isFollowingPitch').setValue(false);
    } else {
      this.form.get('isFollowingPitch').setValue(true);
    }

    if (this.mode == 'new' && value === 'retained'){
      this.form.controls.effectiveAt.patchValue(null);
    }

    this.showMessageTypeRetained(value, this.form?.value?.isFollowingPitch)
  }

  setAgency(movement, type) {
    
    let agency = movement.agency;
    this.selectedAgency = agency;
    this.movementAgency = agency;
    this.form.get('agency_id').patchValue({
      id: agency.id,
      label: agency.name || agency.label
    });

    if (agency.agency_network) {
      let agency_network = agency.agency_network;
      this.form.get('agency_network_id').patchValue(agency_network.id);

      if (agency_network.group) {
        let group = agency_network.group;
        this.form.get('agency_group_id').patchValue(group.id);

        if (group.holding) {
          let holding = group.holding;
          this.form.get('agency_holding_id').patchValue(holding.id);
        }
      }
    }
    if(!this.transferCards){
      this.loadBespokes(agency.id);
    } 
  }

  // Load bespokes of the advertiser
  loadBespokeByAdvertiser(){
     if(this.card && this.card.id){
      this.movementsBespokes = [];  
      return this.dataService.getAsPromise(`admin/card_movements/bespokes/${this.card.id}/advertiser`).then(res => {
        if (res && res.data) {
          res.data.forEach(b => {
            this.movementsBespokes.push(b.bespokeUnit);
          });
        }
        this.filteredBespokes = this.bespokeCtrl.valueChanges.pipe(
          startWith(null),
          map((bespoke: string | null) => bespoke ? this._filter(bespoke) : this.movementsBespokes.slice())
        );
  
        this.bespokeUnitLoader = false;
      }).catch(err => {
        this.bespokeUnitLoader = false;
      })
    }
  }

  // set date effectiveAt depending on movedAt
  changeMovedAtDate(movedAtDate) {
    if (this.mode == 'new' && this.form.value.type === 'retained') {
      this.form.controls.effectiveAt.patchValue(null);
    } else if (movedAtDate){
      let startDate = moment(movedAtDate).startOf('year');
      let endDate = moment(startDate).add(9, 'M');
      let range = moment(movedAtDate).isBetween(startDate, endDate, undefined, '[)');

      if (range) {
        this.form.controls.effectiveAt.patchValue(moment(movedAtDate).add(1, 'M').startOf('month'));
      } else {
        this.form.controls.effectiveAt.patchValue(startDate.add(12, 'M'));
      }
    }
  }

  setTransferAgency(movement, type) {
    let agency = movement.agency;
    if (this.movement && this.movement.agency) {
      this.selectedAgency = this.movement.agency;
      this.movementAgency = this.movement.agency;
      this.form.get('agency_id').patchValue({
        id: this.movement.agency.id,
        label: this.movement.agency.name || this.movement.agency.label
      });
    } else {
      this.selectedAgency = null;
      this.movementAgency = null;
      this.form.get('agency_id').patchValue(null);  
    }

    if (agency.agency_network) {
      let agency_network = agency.agency_network;
      if (this.movement && this.movement.agency && this.movement.agency.agency_network) {
        this.form.get('agency_network_id').patchValue(this.movement.agency.agency_network.id);
      } else {
        this.form.get('agency_network_id').patchValue(null);
      }   

      if (agency_network.group) {
        let group = agency_network.group;
        this.form.get('agency_group_id').patchValue(group.id);
        if (group.holding) {
          let holding = group.holding;
          this.form.get('agency_holding_id').patchValue(holding.id);
        }

        this.agencyExtraParameters = {
          filters:
            [
              {
                operator: 'join',
                joins: [
                  {
                    table: 'agency_networks',
                    on: ['agencies.agency_network_id', 'agency_networks.id']
                  },
                  {
                    table: 'groups',
                    on: ['agency_networks.group_id', 'groups.id']
                  }
                ]
              },
              {
                column: 'groups.id', operator: '=', value: group.id
              }
            ]
        }
      }
    }
    this.loadBespokes(agency.id);
  }

  clearAgency() {
    this.selectedAgency = null;
    this.movementAgency = null;
    this.form.get('agency_id').patchValue(null);
    this.form.get('agency_network_id').patchValue(null);
    this.form.get('agency_group_id').patchValue(null);
    this.form.get('agency_holding_id').patchValue(null);
  }

  calculateContractEndsAt(event) {
    if (event && event.target.value) {
      let moveAt = this.form.value.movedAt;
      if (moveAt) {
        let value = moment(moveAt).add(event.target.value, 'y');
        this.form.get('contractEndsAt').patchValue(value);
      }
    } else {
      this.form.get('contractEndsAt').patchValue(null);
    }
  }

  // set contractDuration depending on movedAt and contractEndsAt
  calculateContractDuration(contractEndsAt) {
    let moveAt = this.form.value.movedAt;
    if (moveAt && contractEndsAt) {
      let duration = contractEndsAt.diff(moveAt, 'years', true);
      this.form.get('contractDuration').patchValue(duration.toFixed(1));
    } else {
      this.form.get('contractDuration').patchValue(null);
    }
  }

  openCityDialog(){
    let item = new City();

    let dialogRef = this.dialog.open(CityDialog, {
      data: {
        title: this.translate.instant('CITIES.DIALOG.NEW_CITY_DIALOG_TITLE'),
        item: item,
        from: 'movement'
      },
      height: 'auto',
      width: '600px',
      autoFocus: false,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      
      if (result) {
        let cities = [];
        this.movement.cities.forEach(c => {
            cities.push({
              id: c.id,
              label: c.name || c.label
            });
        });
        cities.push({
          id: result.id,
          label: result.name || result.label
        })
        this.form.get('cities').patchValue(cities);
        this.cities.loadField();  
      }
    });


  }
  // show a message if type is retained+no pitch(false)
  showMessageTypeRetained(type, pitch){
    if(type && type == 'retained' && pitch == false){
      this.show_retained_msg = true;
    }else{
      this.show_retained_msg = false;
    }
  }

  toggleIsFollowingPitch(event){
    let type = this.form?.value?.type;
    this.showMessageTypeRetained(type, event.checked);
  }

  onPitchPredictorChange(event){

    if(event.value == 'auto'){
      if(this.movement){
        if(this.movement.type == 'transfer'){
          this.selectedPitch = this.movement.getPitchPredictor(this.previousMovement?.movedAt);
        }else{
          this.selectedPitch = this.movement.getPitchPredictor();
        }              
      }
      else{
        this.selectedPitch = 0 
      }
      this.form.controls['pitch_predictor'].setValue('auto'); 
      this.isForced = false;
      
    }
    else{
      this.form.controls['pitch_predictor'].setValue(event.value);
      this.selectedPitch = event.value;
      this.isForced = true;
    }
   
    this.predictorInnerHTML = this.translate.instant("STATIC.PITCH_PREDICTOR."+this.selectedPitch);
  }

  getPitchPredictorOptions(predictor){
    return this.translate.instant("STATIC.PITCH_PREDICTOR."+predictor);
  }

  clearBespoke() {
    this.bespokeUnit = null;
    this.selectedBespokeObject = null;

    if (this.bespokeInput) {
      this.bespokeInput.nativeElement.value = '';
    }
    this.bespokeCtrl.setValue(null);
  }

  openBespokeDialog() {
    let item = null;

    let dialogRef = this.dialog.open(BespokeDialog, {
      data: {
        title: this.translate.instant('BESPOKES.DIALOG.NEW_TITLE'),
        advertiser: this.advertiser,
        agency: this.selectedAgency
      },
      height: 'auto',
      width: '600px',
      autoFocus: false,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadBespokes(this.selectedAgency.id);
        if(result.item) {
          let bespoke = result.item;
          let newBespoke = {
            id: result.item.id,
            name: result.item.name
          };
          this.bespokeUnit = newBespoke;
          this.selectedBespokeObject = {
            id: bespoke.id,
            name: bespoke.name,
            advertiser_id: bespoke.advertiser_id,
            parentco_id: bespoke.parentco_id,
            agency_id: bespoke.agency_id,
            agency_network_id: bespoke.agency_network_id,
            group_id: bespoke.group_id,
            holding_id: bespoke.holding_id
          }
          if (this.bespokeInput) {
            this.bespokeInput.nativeElement.value = '';
            this.bespokeInput.nativeElement.blur()
          }
          this.bespokeCtrl.setValue(null);
        }
      }
    });
  }

  showIsConfidential() {
    let types = ['agency','retained','transfer','new-assignment','contract-renewal'];
    if(types.includes(this.form.value.type)) {
      return true;
    }
    return false;
  }

  onCountryRemove(event) {
    let countries = this.form.value.countries;
    if (this.form.value.countries.length == 0) {
      countries.push(this.allCountriesOption)
      this.form.get('countries').patchValue(countries);
      this.countries.loadField();
    }
  }

  onCountrySelect(event) {
    let countries = this.form.value.countries;
    let o = countries.indexOf('all');
    if (this.form.value.countries.length == 2 && o !== -1) {
      countries = event;
      this.form.get('countries').patchValue(countries);
      this.countries.loadField();
    }
  }

  updateValidityForTransferForm(){
    
    this.form.get('assignments').clearValidators();
    this.form.get('assignments').updateValueAndValidity({emitEvent:false});
    this.form.get('medias').clearValidators();
    this.form.get('medias').updateValueAndValidity({emitEvent:false});
    this.form.get('zoneCoverage').clearValidators();
    this.form.get('zoneCoverage').updateValueAndValidity({emitEvent:false});
    this.form.get('inReviewAt').clearValidators();  
    this.form.get('inReviewAt').updateValueAndValidity({emitEvent:false});
    this.form.get('card_id').setValidators([]);
    this.form.get('card_id').updateValueAndValidity();
    this.form.get('movedAt').clearValidators();
    this.form.get('movedAt').setValidators([Validators.required]);
    this.form.get('movedAt').updateValueAndValidity();
    this.form.get('agency_id').setValidators([Validators.required]);
    this.form.get('agency_id').updateValueAndValidity();
  }


  saveData(){
    this.btn_save_loader = true;
    //open confirm dialog
    this.dataService.postAsPromise(`admin/agencies/card_count/${this.agency?.agency?.id}`, {agency_id: this.agency?.agency?.id,countries: this.form.get('countries').value})
    .then(res=>{
      if(res.data != null){
        let card_count = res.data;
        const dialogRef = this.dialog.open(ConfirmDialog, {
          data: {
            title: this.translate.instant('CARDS.MOVEMENT_DIALOG.CONFIRM_TRANSFER_TITLE'),
            text: this.translate.instant('CARDS.MOVEMENT_DIALOG.CONFIRM_TRANSFER_TEXT',{card_count:card_count}),
          },
          autoFocus: false
        });

        let formData = this.form.value;
        if (formData['effectiveAt'] && formData['effectiveAt'] != '' && typeof (formData['effectiveAt']) == 'object') {
          formData['effectiveAt'] = formData['effectiveAt'].format('YYYY-MM-DD');
        } else if (formData['effectiveAt'] == '') {
          formData['effectiveAt'] = null;
        }
        if (formData['movedAt'] && formData['movedAt'] != '' && typeof (formData['movedAt']) == 'object') {
          formData['movedAt'] = formData['movedAt'].format('YYYY-MM-DD');
        } else if (formData['movedAt'] == '') {
          formData['movedAt'] = null;
        }
    
        if(formData['unknownMovedAt']) {
          formData['movedAt'] = '1970-01-01';
          formData['effectiveAt'] = '1970-01-01';
        }
  
        let data = {
          agency_id: this.agency?.agency?.id || null,
          new_agency_id: formData['agency_id'] || null,
          countries: formData['countries'] || null,
          type: formData['type'] || null,
          isFollowingPitch: formData['isFollowingPitch'] || null,
          contractEndsAt: formData['contractEndsAt'] || null,
          contractDuration: formData['contractDuration'] || null,
          effectiveAt: formData['effectiveAt'],
          movedAt: formData['movedAt'] || null,
          unknownMovedAt: formData['unknownMovedAt'] || null,
          cities: formData['cities'] || null,
          source: formData['source'] || null,
        };
        //set data for transfer
        dialogRef.afterClosed().subscribe(result => {
          if (result == 'bt_yes') {
            let id = this.agency?.agency?.id;
            this.dataService.postAsPromise(`admin/agencies/transfer/${id}`, data)
              .then(res => {
                this.btn_save_loader = false;
                this.dialogRef.close();
              })
              .catch(err => {
                this.btn_save_loader = false;
                console.log(err);
              })
          }else{
            this.btn_save_loader = false;
          }
        });
      }
    })
  }

  saveTransfer(){   
    this.updateValidityForTransferForm();
    this.form.markAllAsTouched();   
    if (this.form.valid) {
      if(!this.form.get('effectiveAt').value.isSame(this.form.get('movedAt').value)){
        let dialogRef = this.dialog.open(ConfirmDialog,{
          data: {
            title: this.translate.instant('CARDS.MOVEMENT_DIALOG.CONFIRM_DATES_TITLE'),
            text: this.translate.instant('CARDS.MOVEMENT_DIALOG.CONFIRM_DATES_TEXT'),
          },
          autoFocus: false
        });
        dialogRef.afterClosed().subscribe(result=>{
          if(result == 'bt_yes'){
            this.saveData();
          }
        })
      }else{
        this.saveData();
      }
    }
  }
}