import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

type PathMatch = "full" | "prefix" | undefined;

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full' as PathMatch
  },
  {
    path: 'login',
    redirectTo: 'auth/login'
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(mod => mod.AuthModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin'] },
    loadChildren: () => import('./modules/pages/pages.module').then(mod => mod.PagesModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.DASHBOARD' },
    loadChildren: () => import('./modules/admin/dashboard/dashboard.module').then(mod => mod.DashboardModule),
  },
  {
    path: 'zones',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.ZONES', showBookmarks: true },
    loadChildren: () => import('./modules/admin/zones/zones.module').then(mod => mod.ZonesModule),
  },
  {
    path: 'currencies',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.CURRENCIES', showBookmarks: true },
    loadChildren: () => import('./modules/admin/currencies/currencies.module').then(mod => mod.CurrenciesModule)
  },
  {
    path: 'subzones',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.SUBZONES', showBookmarks: true },
    loadChildren: () => import('./modules/admin/subzones/subzones.module').then(mod => mod.SubzonesModule),
  },
  {
    path: 'countries',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.COUNTRIES', showBookmarks: true },
    loadChildren: () => import('./modules/admin/countries/countries.module').then(mod => mod.CountriesModule),
  },
  {
    path: 'cities',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.CITIES', showBookmarks: true },
    loadChildren: () => import('./modules/admin/cities/cities.module').then(mod => mod.CitiesModule),
  },
  {
    path: 'parentcogamas',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.PARENTS_CO_GAMA', showBookmarks: true },
    loadChildren: () => import('./modules/admin/parentcoGama/parentcoGama.module').then(mod => mod.ParentcoGamaModule),
  },
  {
    path: 'parentcos',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.PARENTS_CO', showBookmarks: true },
    loadChildren: () => import('./modules/admin/parentco/parentco.module').then(mod => mod.ParentcoModule),
  },
  {
    path: 'advertisers',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.ADVERTISERS', showBookmarks: true },
    loadChildren: () => import('./modules/admin/advertisers/advertisers.module').then(mod => mod.AdvertisersModule),
  },
  {
    path: 'brands',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.BRANDS', showBookmarks: true },
    loadChildren: () => import('./modules/admin/brands/brands.module').then(mod => mod.BrandsModule),
  },
  {
    path: 'categorygamas',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.CATEGORIES_GAMA', showBookmarks: true },
    loadChildren: () => import('./modules/admin/categoriesGama/categoriesGama.module').then(mod => mod.CategoriesGamaModule),
  },
  {
    path: 'categories',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.CATEGORIES', showBookmarks: true },
    loadChildren: () => import('./modules/admin/categories/categories.module').then(mod => mod.CategoriesModule),
  },
  {
    path: 'segments',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.SEGMENTS', showBookmarks: true },
    loadChildren: () => import('./modules/admin/segments/segments.module').then(mod => mod.SegmentsModule),
  },
  {
    path: 'medias',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.MEDIA', showBookmarks: true },
    loadChildren: () => import('./modules/admin/media/media.module').then(mod => mod.MediaModule),
  },
  {
    path: 'assignments',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.ASSIGNMENTS', showBookmarks: true },
    loadChildren: () => import('./modules/admin/assignments/assignments.module').then(mod => mod.AssignmentsModule),
  },
  {
    path: 'holdings',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.HOLDINGS', showBookmarks: true },
    loadChildren: () => import('./modules/admin/holdings/holdings.module').then(mod => mod.HoldingsModule),
  },
  {
    path: 'groups',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.GROUPS', showBookmarks: true },
    loadChildren: () => import('./modules/admin/groups/groups.module').then(mod => mod.GroupsModule),
  },
  {
    path: 'agencynetworks',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.NETWORKS', showBookmarks: true },
    loadChildren: () => import('./modules/admin/agencyNetworks/agencyNetworks.module').then(mod => mod.AgencyNetworksModule),
  },

  {
    path: 'consultants',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.CONSULTANTS', showBookmarks: true },
    loadChildren: () => import('./modules/admin/consultants/consultants.module').then(mod => mod.ConsultantsModule),
  },
  {
    path: 'agencies',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.AGENCIES.AGENCIES', showBookmarks: true },
    loadChildren: () => import('./modules/admin/agencies/agencies.module').then(mod => mod.AgenciesModule),
  },
  {
    path: 'bespokes',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.BESPOKES', showBookmarks: true },
    loadChildren: () => import('./modules/admin/bespokes/bespokes.module').then(mod => mod.BespokesModule),
  },
  {
    path: 'agencyunivers',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.AGENCIESUNIVERS', showBookmarks: true },
    loadChildren: () => import('./modules/admin//agencyUnivers/agency.module').then(mod => mod.AgencyModule)
  },
  {
    path: 'jobtitles',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.JOBTITLES', showBookmarks: true },
    loadChildren: () => import('./modules/admin/jobTitles/jobtitles.module').then(mod => mod.JobTitlesModule),
  },
  {
    path: 'cards',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.CARDS.CARDS', showBookmarks: true },
    loadChildren: () => import('./modules/admin/cards/cards.module').then(mod => mod.CardsModule),
  },
  {
    path: 'admonitorings',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.ADMONITORINGS' },
    loadChildren: () => import('./modules/admin/admonitorings/admonitorings.module').then(mod => mod.AdmonitoringsModule),
  },
  {
    path: 'ms-admonitorings',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.MS_ADMONITORINGS' },
    loadChildren: () => import('./modules/admin/ms-admonitorings/ms-admonitorings.module').then(mod => mod.MsAdmonitoringsModule),
  },
  {
    path: 'bms',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.BMS' },
    loadChildren: () => import('./modules/admin/bms/bms.module').then(mod => mod.BmsModule),
  },
  {
    path: 'nbb',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.NBB' },
    loadChildren: () => import('./modules/admin/nbb/nbb.module').then(mod => mod.NbbModule),
  },
  {
    path: 'headcount',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.HEADCOUNT' },
    loadChildren: () => import('./modules/admin/headcounts/headcounts.module').then(mod => mod.HeadcountsModule),
  },
  {
    path: 'workflows',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.HEADCOUNT' },
    loadChildren: () => import('./modules/admin/workflows/workflows.module').then(mod => mod.WorkflowsModule),
  },
  {
    path: 'talents',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.TALENTS', showBookmarks: true },
    loadChildren: () => import('./modules/admin/talents/talents.module').then(mod => mod.TalentsModule),
  },
  {
    path: 'masu',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.MASU', showBookmarks: true },
    loadChildren: () => import('./modules/admin/maSu/masu.module').then(mod => mod.MasuModule),
  },
  {
    path: 'clients',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin'], title: 'STATIC.NAV.CLIENTS', showBookmarks: true },
    loadChildren: () => import('./modules/admin/clients/clients.module').then(mod => mod.ClientsModule),
  },
  {
    path: 'customers',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.USERS', showBookmarks: true },
    loadChildren: () => import('./modules/admin/customers/customers.module').then(mod => mod.CustomersModule),
  },
  {
    path: 'contacts',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.CONTACTS' },
    loadChildren: () => import('./modules/admin/contacts/contacts.module').then(mod => mod.ContactsModule),
  },
  {
    path: 'parameters',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin'], title: 'STATIC.NAV.PARAMETERS', showBookmarks: true },
    loadChildren: () => import('./modules/admin/parameters/parameters.module').then(mod => mod.ParametersModule),
  },
  {
    path: 'radars',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.RADARS.RADAR', showBookmarks: true },
    loadChildren: () => import('./modules/admin/radars/radars.module').then(mod => mod.RadarsModule),
  },
  {
    path: 'news',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.NEWS', showBookmarks: true },
    loadChildren: () => import('./modules/admin/news/news.module').then(mod => mod.NewsModule),
  },
  {
    path: 'schedules',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.SCHEDULES', showBookmarks: true },
    loadChildren: () => import('./modules/admin/schedules/schedules.module').then(mod => mod.SchedulesModule),
  },
  {
    path: 'searches',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.SEARCHES', showBookmarks: true },
    loadChildren: () => import('./modules/admin/searches/searches.module').then(mod => mod.SearchesModule),
  },
  {
    path: 'bpi',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.MCA', showBookmarks: true },
    loadChildren: () => import('./modules/admin/mca/mca.module').then(mod => mod.McaModule),
  },
  {
    path: 'queries',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.QUERIES', showBookmarks: false },
    loadChildren: () => import('./modules/admin/queries/queries.module').then(mod => mod.QueriesModule),
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.USERS' },
    loadChildren: () => import('./modules/admin/users/users.module').then(mod => mod.UsersModule),
  },
  {
    path: 'lists',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.LISTS' },
    loadChildren: () => import('./modules/admin/value-lists/value-lists.module').then(mod => mod.ValueListsModule),
  },
  {
    path: 'spotlights',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.SPOTLIGHT', showBookmarks: true },
    loadChildren: () => import('./modules/admin/spotlight/spotlight.module').then(mod => mod.SpotlightModule),
  },
  {
    path: 'help',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'comverger'], title: 'STATIC.NAV.HELPMESSAGES' },
    loadChildren: () => import('./modules/admin/helpmessages/helpmessages.module').then(mod => mod.HelpmessagesModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/errors/errors.module').then(mod => mod.ErrorsModule),
  },
  {
    path: '**',
    redirectTo: 'error/404'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
