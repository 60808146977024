import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Location } from '@angular/common';
import {
  Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, Renderer2, ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import moment from 'moment';
import { merge, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ComvService } from 'src/app/services/comv.service';
import { DataService, ListApi } from 'src/app/services/data.service';
import { FileServer } from 'src/app/services/fileserver.service';
import { environment } from 'src/environments/environment';

export interface TableColumn {
  column: string; // object property
  db_field: string; // database field
  label: string; // label
  formatter?: (v: any, mainObject: any) => any; // formatter
  type?: string; // type = number|string|boolean|html|date|menu|server_image|text
  menuName?: string; // if type = menu, give the name of the menu for this.getMenu(menuName)
  width?: number; // column width
  visible?: boolean; // show column in the table
  sortable?: boolean; // show sort
  expandable?: boolean; // add column content in expand
  filter?: boolean; // show filter input
  filterFormatter?(v): any; // filter formatter
  sticky?: string | boolean; // sticky column = true|'start'|'end'
  cssClass?: string; // css class for the column
  cssFn?: (v, mainObject: any) => any; // css function for the column
  disableVisibility?: boolean; // disable column in visibility menu
  tooltip?: string;
  cellTooltip?: {
    text: string;
    icon?: string;
    withVisibility: boolean;
    isVisible: (column: TableColumn, mainObject: any) => any;
  };
  icon?: string;
  onIconClick?: (v, mainObject: any) => any;
  iconPosition?: string; // before|after
  filter_type?: string; // select | nulti-select(with in() statement)
  filter_options?: any;
  clickEvent?: boolean,
  onClick?: (event: MouseEvent, mainObject: any, rowNumber: number) => any;
  filterOnDoubleClick?:boolean,  // add the value in the filter if true
  sortStart?: string, // asc | desc
  isEditableField?(column: TableColumn, mainObject: any): any;
  editableField?: {
    fieldType: string; // text|number
    validators?: any[]; // field validators
    errors?: any[];
    notEditableRowIndex?: number[];
    minRows?: number
  },
  resizable?: boolean,
  minWidth?: number,
  maxWidth?: number,
  hidden?: boolean, // column is totally hidden, but it is included in global search
  excludedFromSearch?: boolean
  secondaryColumn?:string
}

const defaultTableColumn: TableColumn = {
  column: null,
  db_field: null,
  label: null,
  formatter: (v) => {
    return v;
  },
  type: 'string',
  menuName: null,
  width: 200,
  visible: true,
  sortable: true,
  expandable: false,
  filter: true,
  filterFormatter: (v) => {
    return v;
  },
  sticky: false,
  cssClass: '',
  disableVisibility: false, filter_type: null,
  icon: null,
  onIconClick: (v, mainObject: any) => {return},
  iconPosition: 'before',
  filter_options: [],
  clickEvent: false,
  onClick: () => {
    return;
  },
  isEditableField: () => {
    return false;
  },
  resizable: false,
  minWidth: 200,
  maxWidth: null,
  hidden: false,
  excludedFromSearch: false
};

export interface TableRow {
  cssClass?: (mainObject: any) => string;
}

@Component({
  selector: 'mat-table-api',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class TableComponent implements OnInit {
  public ready = false;
  public data: any = [];
  public params = {};
  public resultsLength = 0;
  public isLoadingResults = true;
  public isRateLimitReached = false;
  public displayedDbFields = [];
  public dbFiltrableFields = [];
  // public displayedColumns = [];
  public expandedElement = null;
  public expandedElements = [];
  public filteredColumns = [];
  public expandableColumns = [];
  public currentExpandedContent = null;
  public expandedContents = [];
  public allColumnExpanded: boolean = false;
  public s_visibility = {};
  public s_pageSize = null;
  public storageColSizes = {};
  public storageColFilters = {};
  public storageColSort = {};
  public removableStorageColFilters = [];
  public initialSortCol = null;
  public initialSortDir = null;
  public colTypes = {
    number: {
      defaultWidth: 100,
    },
    boolean: {
      defaultWidth: 100,
    },
    string: {
      defaultWidth: 200,
    },
    html: {
      defaultWidth: 200,
    },
    date: {
      defaultWidth: 100,
    },
    menu: {
      defaultWidth: 60,
    },
    budget_md: {
      defaultWidth: 100,
    },
    budget_pct: {
      defaultWidth: 100,
    },
    budget_md_with_null: {
      defaultWidth: 100,
    },
    budget_pct_with_null: {
      defaultWidth: 100,
    },
  };
  boolean_labels = {
    true: null,
    false: null,
  };

  default_page_size = 100;
  paginator_pageSize = 100;

  filter = '';
  filter_db_fields = {};
  filter_db_fields_values = {};
  filter_db_fields_array_values = {};
  global_filter_value = '';
  visibility_filter_value = '';
  _filterTimeout = null;
  tableColumns: TableColumn[] = [];
  visibilityColumnsMenu: TableColumn[] = [];
  tableRow: TableRow = {};
  filters = [];
  _excludedFromGlobalSearch = ['actions'];
  protected _menus = [];
  public touchedColumns = [];
  public currentColVisibility = [];

  @ViewChild('paginator1', { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable) updateSticky: MatTable<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('visibilityFilterInput', { static: false }) visibilityFilterInput: ElementRef;

  @Input() table: string = null;
  @Input() endpoint: string = null;
  @Input() set columnsDef(columns: TableColumn[]) {
    // debugger;
    const cols = [];
    columns.forEach((c) => {
      const d = Object.assign({}, defaultTableColumn);
      const o = Object.assign(d, c);
      if (!c.width && this.colTypes[o.type]) {
        o.width = this.colTypes[o.type].defaultWidth;
      }
      if (o.type == 'boolean' && !c.formatter) {
        o.formatter = (v) => {
          return v ? this.boolean_labels.true : this.boolean_labels.false;
        };
      }
      if (o.type == 'date' && !c.formatter) {
        o.formatter = (v) => {
          return v ? moment(v).format('YYYY-MM-DD') : '';
        };
      }

      if (o.type == 'budget_md' && !c.formatter) {
        o.formatter = (v) => {
          return v ? Math.round((v + Number.EPSILON) * 10) / 10 : '0.0'; // v ? v.toFixed(1) : '0.0';
        };
      }
      if (o.type == 'budget_pct' && !c.formatter) {
        o.formatter = (v) => {
          return v ? Math.round((v + Number.EPSILON) * 10) / 10 : '0.0'; //v ? v.toFixed(1) : '0.0';
        };
      }

      if (o.type == 'budget_md_with_null' && !c.formatter) {
        o.formatter = (v) => {
          return v !== null ? Math.round((v + Number.EPSILON) * 10) / 10 : null; // v ? v.toFixed(1) : '0.0';
        };
      }
      if (o.type == 'budget_pct_with_null' && !c.formatter) {
        o.formatter = (v) => {
          return v !== null ? Math.round((v + Number.EPSILON) * 10) / 10 +"%": null; //v ? v.toFixed(1) : '0.0%';
        };
      }

      if (o.type == 'server_image' && !c.formatter) {
        o.formatter = (v) => {
          return v ? this.location.normalize(this.fs_images + v) : null;
        };
      }

      if (o.type == 'server_media' && !c.formatter) {
        o.formatter = (v) => {
          return v ? this.fileserver.getMediaFile(v) : null;
        };
      }

      // filterFormatter for number type
      if (o.type == 'number' && !c.filterFormatter) {
        o.filterFormatter = (v) => {
          if (v != '') {
            switch (v) {
              case '^$':
                break;
              case '$^':
                break;
              default:
                let first = v.slice(0, 1);
                let last = v.slice(-1);

                // return exact value(use '=' instead 'like')
                if (first != '^' && last != '$') {
                  v = '^' + v + '$';
                }
                break;
            }
          }
          return v;
        };
      }

      // multi-select type
      if(o.filter_type == 'multi-select') {
        o.filter_options = this.setMultiseleftFilterOptions(o.filter_options);
      }

      cols.push(o);
    });
    this.tableColumns = cols;
  }
  @Input() set rowDef(row: TableRow) {
    this.tableRow = row;
  }

  @Input() enableVisibilityFilter: boolean;
  @Input() enableResetColumns: boolean = true;
  @Input() withFilterField: boolean = true;
  @Input() withColumnFilters: boolean = true;
  @Input() withColumnVisibility: boolean = true;
  @Input() expandable: boolean = false;
  @Input() expandOnRowClick: boolean = true;
  @Input() expandIcon: boolean = false;
  @Input() expandIconAll: boolean = false;
  @Input() checkRow: boolean = false;
  @Input() expandableTemplate: string = null;
  @Input() expandAllRows: boolean = false;
  @Input() sortActiveColumn: string = null;
  @Input() sortActiveColumnDirection: SortDirection = 'asc';
  @Input() excludedFromGlobalSearch = [];
  @Input() filterByParam = {};
  @Input() set menus(m) {
    this._menus = m;

    if (this.enableResetColumns && this.withColumnVisibility && this._menus['topMenu']) {
      let o = _.find(this._menus['topMenu'], {id: 'reset_col_visibility'});
      if(!o) {
        this._menus['topMenu'].push({
          id: 'reset_col_visibility',
          icon: 'grid_on',
          label: this.translate.instant('BUTTONS.BT_RESET_COLUMN_VISIBILITY'),
          click: () => {
            this.resetColumnVisibility();
          },
        });
      }
    }
  }

  @Input() Model = null;

  @Input() set extraParameters(p) {
    this._extraParameters = p;
  }
  private _extraParameters = {};

  @Input() rememberFilters = true;
  @Input() storageFiltersTimeout = 0; // minutes | 0 = unlimited
  @Input() withCellClick = false; // event on row click
  @Input() routeFiltersAllow = false; // take filters from activate route
  @Input() observeRouteFilters = false; // take filters from activate route
  @Input() updateRouteFilters = false; // take filters from activate route

  @Input() buildRouteFilters: boolean = false;
  @Input() set routeFilters(r) {
    this._routeFilters = r;
  }
  public _routeFilters = null;

  @Output() onRefresh: EventEmitter<any> = new EventEmitter();
  @Output() expand: EventEmitter<any> = new EventEmitter();
  @Output() menuEvent: EventEmitter<any> = new EventEmitter();
  @Output() afterMapData: EventEmitter<any> = new EventEmitter();
  @Output() checkedRows: EventEmitter<any> = new EventEmitter();
  @Output() onCellClick: EventEmitter<any> = new EventEmitter();
  @Output() onColumnFilter: EventEmitter<any> = new EventEmitter();
  @Output() onApplyFilter: EventEmitter<any> = new EventEmitter();
  @Output() saveByRow: EventEmitter<any> = new EventEmitter();
  @Output() editableFieldInput: EventEmitter<any> = new EventEmitter();
  @Output() onRowDbClick: EventEmitter<any> = new EventEmitter();

  protected fs_images = environment.fileserver.images;
  protected filtersFromRoute = {};
  protected controlsFromRoute = {};
  protected fFromRoute = {};
  protected cFromRoute = {};
  protected firstFragmentSet = true;
  protected fragmentChanged = false;
  protected isFirstInit: boolean=true;
  public editableFieldsForms = {};
  public globalSearchMode = null; // s=strict|sw=start|ew = end with|default=contain

  constructor(
    public auth: AuthenticationService,
    protected dataService: DataService,
    protected translate: TranslateService,
    protected comvService: ComvService,
    private sanitizer: DomSanitizer,
    protected location: Location,
    private route: ActivatedRoute,private zone: NgZone,
    private elRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private fileserver: FileServer
  ) {
    this.boolean_labels.true = this.translate.instant('TABLE.YES');
    this.boolean_labels.false = this.translate.instant('TABLE.NO');
  }

  ngOnInit() {
    // set initial sort&direction
    const sortActiveColumn = this.sortActiveColumn;
    const sortActiveColumnDirection = this.sortActiveColumnDirection;
    this.initialSortCol = sortActiveColumn;
    this.initialSortDir = sortActiveColumnDirection;
    this.preperVars();
    this.initialSortCol = this.initialSortCol
      ? this.initialSortCol
      : this.displayedDbFields[0];
    this._excludedFromGlobalSearch = this._excludedFromGlobalSearch.concat(
      this.excludedFromGlobalSearch
    );
    
      this.visibilityColumnsMenu = this.tableColumns.filter(option => !option.hidden);
  }

  // allow styling in innerHTML
  AllowStylingInnerHTML(htmlTextWithStyle) {
    if(!htmlTextWithStyle) {
      return null;
    }
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }

  ngAfterViewInit() {
    // use filters from activate route
    if(this.routeFiltersAllow) {
      if(this.buildRouteFilters && this._routeFilters) {
        this.setFiltersFromRoute(this._routeFilters);
      } else {
        this.route.fragment.subscribe(fragment => {
          this.setFiltersFromRoute(fragment);
        });
      }
    } else if(this.buildRouteFilters) {
      this.setFiltersFromRoute(this._routeFilters);
    }

    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    let t1 = 0;
    let t2 = 0;

    setTimeout(() => {
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          startWith({}),
          // debounceTime(200),
          switchMap((value , index) => {
            // temporary fix for Bug #8959
            let data = this.getStorageData();
            if (data[this.table] && data[this.table]['columns_filters']) {
              if(data[this.table]['columns_filters']['moveType'] && data[this.table]['columns_filters']['moveType'].startsWith("in*(")) {
                this.onResetFilter('moveType'); 
              }
            }
            
            let excluded_cols = this._excludedFromGlobalSearch;
            let dbVisibleFields = this.displayedDbFields.filter((el) => {
              return !excluded_cols.includes(el);
            });

            t1 = new Date().getTime();
            this.isLoadingResults = true;

            let sort = this.sort.active;
            let dir = this.sort.direction;
            let page = this.paginator.pageIndex + 1;
            let per_page = this.paginator.pageSize;

            if(this.routeFiltersAllow  || this.buildRouteFilters) {
              if(this.fragmentChanged) { // when fragment is changed, get controls from url
                const controlsFromRoute = this.cFromRoute;
                sort = controlsFromRoute['sort'] ?? sort;
                dir = controlsFromRoute['dir'] ?? dir;
                page = controlsFromRoute['page'] ?? page;
                per_page = controlsFromRoute['per_page'] ?? per_page;
                if(this.updateRouteFilters) {
                  const o = {};
                  if(!(sort == this.initialSortCol && dir == this.initialSortDir)) {
                    o['sort'] = sort; 
                    o['dir'] = dir;
                  }
                  // sync if no default page index
                  if(page !== 1) {
                    o['page'] = page;
                  }
                  // sync if no default paginator size
                  if(per_page !== this.default_page_size) {
                    o['per_page'] = per_page;
                  }
                  this.controlsFromRoute = o;
                }
              } else if(this.updateRouteFilters || this.buildRouteFilters){ // when table is changed, update the url
                if(!(sort == this.initialSortCol && dir == this.initialSortDir)) {
                  this.controlsFromRoute['sort'] = sort;
                  this.controlsFromRoute['dir'] = dir;
                }
                // sync if no default page index
                if(page !== 1) {
                  this.controlsFromRoute['page'] = page;
                } else {
                  delete this.controlsFromRoute['page'];
                }
                // sync if no default paginator size
                if(per_page !== this.default_page_size) {
                  this.controlsFromRoute['per_page'] = per_page;
                } else {
                  delete this.controlsFromRoute['per_page'];
                }
              }
            }

            this.params = {
              sort: sort,
              dir: dir,
              page: page,
              per_page: per_page,
              visible_columns: JSON.stringify(dbVisibleFields),
            };
            if (this._extraParameters) {
              this.params = Object.assign({}, this.params, this._extraParameters);
            }

            // remember col sort
            this.rememberColSort();

            this.filters = [];
            // global filter
            if ((this.routeFiltersAllow || this.buildRouteFilters) && this.objectSize(this.filtersFromRoute) > 0) {
              if(this.filtersFromRoute['search']) {
                this.filter = this.filtersFromRoute['search'];
                this.global_filter_value = this.filtersFromRoute['search'];
              }
            }
            if (this.filter.length) {
              let filtered_columns = {};
              this.dbFiltrableFields.forEach((col) => {
                if (!excluded_cols.includes(col)) {
                  let o = _.find(this.tableColumns, { db_field: col });
                  let filterValue = encodeURIComponent(o.filterFormatter(this.filter));
                  if(this.globalSearchMode) {
                    if(this.globalSearchMode == 's') { // strict
                      filterValue = '^'+filterValue+'$';
                    } else if(this.globalSearchMode == 'sw') { // start with
                      filterValue = '^'+filterValue;
                    } else if(this.globalSearchMode == 'ew') { // end with
                      filterValue = filterValue+'$';
                    }
                  }
                  if(o.type !== 'boolean' && !o.excludedFromSearch) {
                    filtered_columns[col] = filterValue;
                  }
                }
              });
              this.filters.push(this.getFilters(filtered_columns, 'OR'));

              // remember global filter
              if (this.rememberFilters) {
                this.rememberColFilters('global_filter', this.filter);
              }
            } else {
              // clear global filter
              if (this.rememberFilters) {
                this.clearStorageColFilters('global_filter');
              }
            }

            // filterByParam
            if (this.objectSize(this.filterByParam) > 0) {
              for (let p in this.filterByParam) {
                this.filter_db_fields[p] = this.filterByParam[p];
              }
            }
            this.filterByParam = {};


            // column filter
            if (
              this.rememberFilters &&
              this.objectSize(this.storageColFilters) > 0
            ) {
              for (let ff in this.storageColFilters) {
                let c = _.find(this.tableColumns, { db_field: ff });
                if (c) {
                  this.applyColFilter(this.storageColFilters[ff], c);
                }
              }
            }

            // filtersFromRoute
            if ((this.routeFiltersAllow || this.buildRouteFilters) && this.objectSize(this.filtersFromRoute) > 0) {
            // if (this.routeFiltersAllow && this.objectSize(this.filtersFromRoute) > 0) {
              this.filter_db_fields = {};
              for (let fl in this.filtersFromRoute) {
                let c = _.find(this.tableColumns, { db_field: fl });
                if(c) {
                  this.filter_db_fields[fl] =  c.filterFormatter(this.filtersFromRoute[fl]);
                }
              }
              
              // add multi select default values
              this.setMultiSelectDefaultValue();
            }

            let visible_filter_db_fields_encoded = JSON.parse(JSON.stringify(this.filter_db_fields));
            if (this.objectSize(this.filter_db_fields) > 0) {
              // remove hidden fields from filters
              visible_filter_db_fields_encoded = {};
              let visible_filter_db_fields = {};
              for (let ff in this.filter_db_fields) {
                let c = _.find(this.tableColumns, { db_field: ff });
                if (this.displayedDbFields.includes(ff) || (c && c.hidden)) {
                  visible_filter_db_fields_encoded[ff] = encodeURIComponent(this.filter_db_fields[ff]);
                  visible_filter_db_fields[ff] = this.filter_db_fields[ff];
                }
              }
              let filter_db_fields = visible_filter_db_fields_encoded;
              this.filter_db_fields = visible_filter_db_fields;


              this.filters.push(this.getFilters(filter_db_fields, 'ANDD'));
            
              // this.filter_db_fields = visible_filter_db_fields;

              // this.filters.push(this.getFilters(this.filter_db_fields, 'ANDD'));
            } else {
              // clear cols filters
              if (this.rememberFilters) {
                this.clearStorageColFilters(null, 'col_filter');
              }
            }

            // update the route filters
            if((this.routeFiltersAllow && this.updateRouteFilters) || this.buildRouteFilters) {
            // if(this.routeFiltersAllow && this.updateRouteFilters) {
              let routeFields = JSON.parse(JSON.stringify(visible_filter_db_fields_encoded));
              if(this.filter) {
                routeFields['search'] = this.filter
              }
              this.rebuildRouteWithFilters(routeFields, this.controlsFromRoute, this.updateRouteFilters);
            }

            if (this.filters.length) {
              // set filters values
              this.filters = this.setFiltersValuesForRequest(this.filters)
              this.params['filter'] = JSON.stringify(this.filters);
            }

            this.fragmentChanged = false;

            return this.dataService.getList(this.endpoint, this.params);
          }),
          map((data: ListApi) => {
            // Flip flag to show that loading has finished.
            t2 = new Date().getTime();
            this.isLoadingResults = false;
            this.isRateLimitReached = false;
            this.resultsLength = data.meta.pagination.total;
            if (data && data['_debugbar'] && data['_debugbar'].time) {
              // console.log('debugbar', data['_debugbar'].time.duration_str);
            }

            // remove checked rows
            this.clearCheckedRows();

            this.afterMapData.emit(data);
            return data.data;
          }),
          catchError(() => {
            this.isLoadingResults = false;
            // Catch if the API has an error. Return empty data.
            this.isRateLimitReached = true;
            return of([]);
          })
        )
        .subscribe((data) => {
          if (this.Model) {
            this.data = [];
            data.forEach((u) => {
              let el = u;
              el = new this.Model(u);
              this.data.push(el);
            });
          } else {
            this.data = data;
          }
          if(this.expandAllRows) {
            this.expandAll();
          } else {
            this.unExpandAll();
          }

          if(this.checkRow) {
            this.allRowsChecked = false;
            this.setCheckedRows();
          }

          this.isFirstInit = false;

          // set editableFieldsForms
        this.setEditableFieldsForms(this.data);
        });
      }, 200)
  }

  setFiltersFromRoute(fragment = null) {
    // clear all col filters(if observeRouteFilters)
    if((this.firstFragmentSet || this.observeRouteFilters) && this.objectSize(this.filtersFromRoute)) {
      // reset column filters
      this.filter_db_fields = {};
      for (let fcv in this.filter_db_fields_values) {
        this.filter_db_fields_values[fcv] = '';
      }
    }

    const f = fragment;
    let filters = {};
    let controlsFromRoute = {};
    if(f) {
      filters = {};
      const split = f.split(";");
      split.forEach(value => {
        const parts = value.split("=");
        let column_name = parts[0];
        if(column_name) {
          let db_field =  null;
          let o = _.find(this.tableColumns, {column: column_name});
          if(o) {
            db_field = o.db_field;
          }  else if(column_name =='search') { // global filter
            db_field = 'search';
          } else if(column_name =='m') {
            this.globalSearchMode = parts[1];
          } else { // sort | dir | page | per_page
            let m = ['sort', 'dir', 'page', 'per_page'];
            if(m.indexOf(column_name) != -1) {
              switch(column_name) {
                case 'sort':
                  let col = _.find(this.tableColumns, {column: parts[1]});
                  if(col) {
                    controlsFromRoute['sort'] = col.db_field;
                  }
                  break;
                case 'dir':
                case 'page':
                case 'per_page':
                  if(parts[1]) {
                    controlsFromRoute[column_name] = parts[1];
                  }
                  break;
              }
            }
          }
          if(db_field !== null) {
            let v = parts[1] || null
            // set values for type = boolean
            if(o && o.type == 'boolean') {
              if(v !== '') {
                v = v == "1" ? 1 : 0;
              }
            }
            filters[db_field] = v;
          }
        }
      });
    }
    this.fFromRoute = filters;
    this.cFromRoute = controlsFromRoute;

    if(this.firstFragmentSet || this.observeRouteFilters) {
      setTimeout(() => {
        this.filtersFromRoute = filters;
        this.controlsFromRoute = controlsFromRoute;
        this.setTableColumns();
        this.fragmentChanged = true;
        // avoid consecutive refresh
        if(!this.isFirstInit) {
          this.refresh();
        }
        this.firstFragmentSet = false;
      });
    }
  }

  syncPrimaryPaginator(event: PageEvent) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.page.emit(event);
  }

  preperVars() {
    let storage_data = this.getStorageData();
    let table_storage_data = storage_data[this.table];

    // remove filters from storage
    if (this.rememberFilters && storage_data && this.storageFiltersTimeout) {
      // if (this.rememberFilters && table_storage_data['columns_filters']) {
      if (storage_data['filters_timeout']) {
        const unixTimestamp = storage_data['filters_timeout'];
        const milliseconds = storage_data['filters_timeout'] * 1000;
        const dateObject = new Date(milliseconds);

        let futureDate = new Date(
          dateObject.getTime() + this.storageFiltersTimeout * 60000
        );
        let nowDate = new Date();
        let nowTimeout = this.toTimestamp(nowDate);

        if (nowTimeout > this.toTimestamp(futureDate)) {
          this.clearStorage();
          // this.clearStorageColFilters();
          // this.clearColSort();
          this.removableStorageColFilters = [];
          table_storage_data = null;
        }
      }
    }

    if (table_storage_data) {
      this.s_visibility = table_storage_data['columns_visibility'];
      this.s_pageSize = table_storage_data['pageSize'];
      this.storageColFilters = table_storage_data['columns_filters'];
      this.storageColSort = table_storage_data['sort'];
      this.storageColSizes = table_storage_data['columns_sizes'] || {};
    }

    this.rememberStorageTimeout();

    this.setTableColumns();

    if (this.s_pageSize) {
      this.paginator_pageSize = this.s_pageSize;
    }
  }

  isTableColumnVisible(element) {
    let visibility = element.visible;
    if ( this.withColumnVisibility ) {
      if(this.s_visibility && this.s_visibility.hasOwnProperty(element.column)) {
        visibility = this.s_visibility[element.column];
      }

      if((this.routeFiltersAllow || this.buildRouteFilters)
        && this.objectSize(this.filtersFromRoute) 
        && this.filtersFromRoute[element.db_field]) {
        visibility = true;
      }
    }
    return visibility;
  }

  setTableColumns() {
    // displayedDbFields
    this.displayedDbFields = [];
    if (this.expandIcon) {
      this.displayedDbFields.push('expand-icon');
    }

    if(this.checkRow) {
      this.displayedDbFields.push('check-row');
    }
    this.currentColVisibility = [];
    this.tableColumns.forEach((element) => {
      // if (this.s_visibility && this.s_visibility.hasOwnProperty(element.column)) {
      //   element.visible = this.s_visibility[element.column];
      // }

      let visibility = element.visible;
      if (this.withColumnVisibility) {
        if(this.s_visibility && this.s_visibility.hasOwnProperty(element.column)) {
          visibility = this.s_visibility[element.column];
        }

        // take visibility from foute filters
        if((this.routeFiltersAllow || this.buildRouteFilters)
          && (
            (this.filtersFromRoute[element.db_field]) 
            || (this.controlsFromRoute['sort'] && this.controlsFromRoute['sort'] == element.db_field)
          )) {
          visibility = true;
        }
      }

      this.currentColVisibility[element.column] = visibility;
      // if (this.isTableColumnVisible(element)) {
      if (visibility && !element.hidden) {
        this.displayedDbFields.push(element.db_field);
        if (element.filter) {
          this.dbFiltrableFields.push(element.db_field);
        }
      } else if ((element.expandable && element.filter) || element.hidden) {
        this.dbFiltrableFields.push(element.db_field);
      }

      // filterByParam
      if (this.objectSize(this.filterByParam) > 0) {
        for (let p in this.filterByParam) {
          this.filter_db_fields_values[p] = this.filterByParam[p];
        }
      }


      // storage col filters
      if (this.rememberFilters && this.objectSize(this.storageColFilters) > 0) {
        for (let f in this.storageColFilters) {
          this.filter_db_fields_values[f] = this.storageColFilters[f];
          // multi select
          let o = _.find(this.tableColumns, {db_field: f});
          if(o && o.filter_type == 'multi-select') {
            let v = this.storageColFilters[f];
            let array = this.decodePowersOfTwo(v);
            this.filter_db_fields_array_values[f] = array;
          }
        }
      }

      // set default selected options for multi select
      if(element.filter_type == 'multi-select' && element.filter_options) {
        let selectedOptions = [];
        element.filter_options.forEach(option => {
          if(option.selected) {
            selectedOptions.push(option.value);
          }
        })
        if(selectedOptions.length) {
          let defaultValue = selectedOptions.reduce((acc, val) => acc + Number(val), 0)
          this.filter_db_fields_array_values[element.column] = selectedOptions;
          this.applyColFilter(defaultValue.toString(), element);
        }
      }

      if (!this.filter_db_fields_values) {
        this.filter_db_fields_values[element.db_field] = '';
      }

      // expandableColumns
      if (element.expandable) {
        this.expandableColumns.push({
          column: element.column,
          db_field: element.db_field,
        });
      }
    });

    // storage global filter
    if (
      this.rememberFilters &&
      this.objectSize(this.storageColFilters) > 0 &&
      typeof this.storageColFilters['global_filter'] !== 'undefined'
    ) {
      this.global_filter_value = this.storageColFilters['global_filter'];
      this.filter = this.storageColFilters['global_filter'];
    }

    // filtersFromRoute
    if ((this.routeFiltersAllow || this.buildRouteFilters) && this.objectSize(this.filtersFromRoute) && (this.firstFragmentSet || this.observeRouteFilters)) {
    // if (this.routeFiltersAllow && this.objectSize(this.filtersFromRoute) && (this.firstFragmentSet || this.observeRouteFilters)) {
      this.filter_db_fields_values = {};
      this.filter_db_fields_array_values = {};
      for (let f in this.filtersFromRoute) {
        this.filter_db_fields_values[f] = this.filtersFromRoute[f];
        // multi select
        let o = _.find(this.tableColumns, {db_field: f});
        if(o && o.filter_type == 'multi-select') {
          let v = this.filtersFromRoute[f];
          let array = this.decodePowersOfTwo(v);
          this.filter_db_fields_array_values[f] = array;
        }
      }
      if(this.filtersFromRoute['search']) {
        this.filter = this.filtersFromRoute['search'];
        this.global_filter_value = this.filtersFromRoute['search'];
      }
    }

    // filteredColumns
    this.filteredColumns = [];
    if (this.withColumnFilters) {
      this.displayedDbFields.forEach((element) => {
        this.filteredColumns.push(element + '_filter');
      });
    }

    // sortActiveColumn
    if (this.rememberFilters && this.storageColSort && this.objectSize(this.storageColSort) > 0) {
      this.sortActiveColumn = (this.storageColSort['column'] && this.storageColSort['column'] !== null)
        ? this.storageColSort['column']
        : (this.sortActiveColumn ? this.sortActiveColumn  : this.displayedDbFields[0]);
      this.sortActiveColumnDirection = (this.storageColSort['dir'] && this.storageColSort['dir'] !== null )
        ? this.storageColSort['dir']
        : this.sortActiveColumnDirection;
    } else if (!this.sortActiveColumn) {
      this.sortActiveColumn = this.displayedDbFields[0];
    }

    // controlsFromRoute
    if ((this.routeFiltersAllow || this.buildRouteFilters) && (this.firstFragmentSet || this.observeRouteFilters)) {
    // if (this.routeFiltersAllow && (this.firstFragmentSet || this.observeRouteFilters)) {
      // sort
      if(this.controlsFromRoute['sort']) {
        this.sortActiveColumn = this.controlsFromRoute['sort'];
      }

      // sort direction
      const directions = ['asc', 'desc']
      if(this.controlsFromRoute['dir'] && directions.indexOf(this.controlsFromRoute['dir']) != -1) {
        this.sortActiveColumnDirection = this.controlsFromRoute['dir'];
      }

      // page
      if(this.controlsFromRoute['page']) {
        const page = Number(this.controlsFromRoute['page']);
        if(!Number.isNaN(page) && page > 0) {
          this.paginator.pageIndex = page - 1;
        }
      } else { // default page index
        if(this.paginator) {
          this.paginator.pageIndex = 0;
        }
      }

      // items per page
      if(this.controlsFromRoute['per_page']) {
        const per_page = Number(this.controlsFromRoute['per_page']);
        if(!Number.isNaN(per_page) && per_page >= 0) {
          this.paginator.pageSize = per_page;
        }
      } else { // default page size
        if(this.paginator && !this.routeFiltersAllow) {
          const default_page_size = this.default_page_size;
          this.paginator.pageSize = default_page_size;
        }
      }
    }

    // update sticky column styles
    setTimeout(() => {
      if(this.updateSticky){
          this.updateSticky.updateStickyColumnStyles();
        }
    }, 500);
    
  }

  isSticky(column: TableColumn, sticky_mode: string) {
    if (sticky_mode == 'start') {
      if (
        (column.sticky && column.sticky == 'start') ||
        column.sticky === true
      ) {
        return true;
      }
    }

    if (sticky_mode == 'end') {
      if (column.sticky && column.sticky == 'end') {
        return true;
      }
    }

    return false;
  }

  applyVisibilityFilter(){
    const filterValue = this.visibility_filter_value.trim().toLowerCase();
    if(filterValue){
      this.visibilityColumnsMenu = this.tableColumns.filter(option => option.label.toLowerCase().includes(filterValue) &&  !option.hidden);
    } else {
      this.clearVisibilityFilterInput();
    }
  }

  clearVisibilityFilterInput(){
    this.visibility_filter_value = '';
    this.visibilityColumnsMenu = this.tableColumns.filter(option => !option.hidden);
  }

  applyFilter(filterValue: string) {
    this.filter = filterValue.trim().toLowerCase();
    if (this._filterTimeout) {
      clearTimeout(this._filterTimeout);
    }
    // if (this.filter.length >= 2 || this.filter.length == 0) {
    this._filterTimeout = setTimeout(() => {
      // sync filtersFromRoute
      if(this.routeFiltersAllow || this.buildRouteFilters) {
        this.filtersFromRoute['search'] = filterValue.trim().toLowerCase();
        this.rebuildRouteWithFilters(this.filtersFromRoute, this.controlsFromRoute, this.updateRouteFilters);
      }

      // reset pagination
      this.paginator.pageIndex = 0;
      this.refresh();
      this.onApplyFilter.emit(true);
    }, 500);
    // }
  }

  applyColFilter(filterValue: string, column: TableColumn, event = null) {
    let db_field = column.db_field;
    filterValue = column.filterFormatter(filterValue);

    if (column.type !== 'boolean') {
      // if (filterValue.length >= 2 || filterValue.length == 0) {
      this.filter_db_fields[db_field] = filterValue.trim().toLowerCase();
      // }
    } else { // type boolean
      // if(filterValue !== '') {
      //   this.filter_db_fields[db_field] = filterValue ? 1 : 0;
      // } else {
        this.filter_db_fields[db_field] = filterValue;
      // }
    }

    let filter_fields = {};
    for (let ff in this.filter_db_fields) {
      if (this.filter_db_fields[ff] !== '') {
        filter_fields[ff] = this.filter_db_fields[ff];
        if (this.rememberFilters) {
          let indexOf = this.removableStorageColFilters.indexOf(ff);
          if (indexOf > -1) {
            this.removableStorageColFilters.splice(indexOf, 1);
          }
        }
      } else {
        if (this.rememberFilters) {
          let indexOf = this.removableStorageColFilters.indexOf(ff);
          if (indexOf === -1) {
            this.removableStorageColFilters.push(ff);
          }
        }
      }
    }
    this.filter_db_fields = filter_fields;
    if (this._filterTimeout) {
      clearTimeout(this._filterTimeout);
    }
    if (typeof filterValue == 'boolean') {
      this._filterTimeout = setTimeout(() => {
        // this.onColFilter();
      }, 500);
    }
  }

  onColFilter() {
    // remove col filters
    if (this.rememberFilters) {
      for (let fcv in this.filter_db_fields_values) {
        if (this.filter_db_fields_values[fcv] !== '') {
          this.rememberColFilters(fcv, this.filter_db_fields_values[fcv]);
        } else {
          this.clearStorageColFilters(fcv);
        }
      }

      this.removableStorageColFilters.forEach((f) => {
        this.clearStorageColFilters(f);
      });
      this.removableStorageColFilters = [];
    }

    // sync filtersFromRoute
    if(this.routeFiltersAllow || this.buildRouteFilters) {
    // if(this.routeFiltersAllow) {
      this.filtersFromRoute = {};
      for (let fcv in this.filter_db_fields_values) {
        if (this.filter_db_fields_values[fcv] !== '') {
          this.filtersFromRoute[fcv] = this.filter_db_fields_values[fcv];
        }
      }
      if(this.filter) {
        this.filtersFromRoute['search'] = this.filter;
      }
      this.rebuildRouteWithFilters(this.filtersFromRoute, this.controlsFromRoute, this.updateRouteFilters);
    }
    // reset pagination
    this.paginator.pageIndex = 0;

    this.refresh();
    this.onColumnFilter.emit(true);
  }

  onResetFilters() {
    // reset global filter
    this.filter = '';
    this.global_filter_value = '';

    // reset column filters
    this.filter_db_fields = {};
    for (let fcv in this.filter_db_fields_values) {
      this.filter_db_fields_values[fcv] = '';
    }
    // reset multi select
    for (let fcv in this.filter_db_fields_array_values) {
      this.filter_db_fields_array_values[fcv] = [];
    }
    // keep the default selected options from column definition
    const multiSelectColumns = this.tableColumns.filter(column => {
      return column.filter_type == 'multi-select';
    })
    multiSelectColumns.forEach((c) => {
      if(c.filter_options) {
        let selectedOptions = [];
        c.filter_options.forEach(option => {
          if(option.selected) {
            selectedOptions.push(option.value);
          }
        })
        if(selectedOptions.length) {
          let defaultValue = selectedOptions.reduce((acc, val) => acc + Number(val), 0);
          this.filter_db_fields_array_values[c.column] = selectedOptions;
          this.applyColFilter(defaultValue.toString(), c);
        }
      }
    })

    // clear storage col filters
    if (this.rememberFilters) {
      this.removableStorageColFilters = [];
      this.clearStorageColFilters();
    }

    if(this.routeFiltersAllow) {
      this.filtersFromRoute = {};
      this.controlsFromRoute = {};
      // clean the route
      const pathWithoutHash = this.location.path(false); 
      this.location.replaceState(pathWithoutHash);
    }

    if(this.buildRouteFilters || this.routeFiltersAllow) {
      this.filtersFromRoute = {};
      this.controlsFromRoute = {};
      this._routeFilters = null; 
    }

    this.clearPaginator();

    setTimeout(() => {
      this.clearColSizes();
      // reset sort
      this.resetSort();
      if(this.checkRow) {
        this.allRowsChecked = false;
        this.onCheckAllRows({checked: false});
      }
      this.refresh();
    });
  }

  onResetFilter(filter) {
    delete this.filter_db_fields_values[filter];

    // reset column filters
    delete this.filter_db_fields[filter];
    this.filter_db_fields_values[filter] = '';

    // clear storage col filters
    if (this.rememberFilters) {
      // this.removableStorageColFilters = [];
      this.clearStorageColFilters(filter);
    }

    if(this.routeFiltersAllow) {
      delete this.filtersFromRoute[filter];
      delete this.controlsFromRoute[filter];
      // clean the route
      const pathWithoutHash = this.location.path(false); 
      this.location.replaceState(pathWithoutHash);
    }

    if(this.buildRouteFilters || this.routeFiltersAllow) {
      delete this.filtersFromRoute[filter];
      delete this.controlsFromRoute[filter];
      this._routeFilters = null; 
    }
  }

  resetSort(withRefresh = false) {
    this.clearColSort();
    // this.sort.sort({
    //   id: this.initialSortCol,
    //   start: 'asc',
    //   disableClear: false,
    // });
    this.sort.direction = this.initialSortDir;
    this.sort.active = this.initialSortCol;
    this.sortActiveColumnDirection = this.initialSortDir;
    this.sortActiveColumn = this.initialSortCol;

    if(withRefresh) {
      this.refresh();
    }
  }

  refresh() {
    this.paginator._changePageSize(this.paginator.pageSize);

    // the table it was refreshed
    this.onRefresh.emit(true);
  }

  onExpand(item = null) {
    if (this.expandable) {
      if (!item) {
        this.expandAll();
      } else {
        this.expandRow(item);
      }
    }
  }

  expandTemplate(item) {
    let self = this;
    let template = '';

    let expandableColumns = null;
    if (this.expandableColumns) {
      expandableColumns = this.expandableColumns;
    }

    let columnType = 'html';

    if (this.expandableTemplate) {
      template += this.expandableTemplate;

      template = template.replace(/{{\w+}}/g, function (variable) {
        let column = variable.split('{{').pop().split('}}')[0];
        column = column.replace(/\s/g, '');

        // visibility
        let visibility = column.substr(0, column.indexOf('_'));
        var col = column.substring(column.indexOf('_') + 1);
        if (visibility === 'visibilityclass') {
          column = col;
        }

        let c = _.find(self.tableColumns, { column: column });
        if (c) {
          columnType = c.type;
        }

        let columnContent = '';
        if (expandableColumns) {
          let o = _.find(expandableColumns, { column: column });
          if (o) {
            if (c) {
              columnContent = item[column]
                ? c.formatter(item[column], item)
                : '';
            } else {
              columnContent = item[column] || '';
            }
          }
        }

        // return columnContent a class name for visible/not visible
        if (visibility === 'visibilityclass') {
          if (columnContent == '' || columnContent == null) {
            columnContent = 'no-content';
          } else {
            columnContent = 'with-content';
          }
        }

        return columnContent;
      });
    } else {
      if (expandableColumns) {
        expandableColumns.forEach((col) => {
          let column_content = this.getProperty(col.column, item);
          template += '<div>' + col.column + ' = ' + column_content + '</div>';
        });
      }
    }

    return {
      content: template,
      column_type: columnType,
    };
  }

  expandRow(row) {
    let id = row.id;
    let expand = false;
    if (this.expandedElements.indexOf(id) > -1) {
      expand = false;
      let index = this.expandedElements.indexOf(id);
      this.expandedElements.splice(index, 1);
    } else {
      expand = true;
      this.expandedElements.push(id);

      let item = row;
      let template = this.expandTemplate(item);
      let expanded_item = {
        item_id: item.id,
        column_type: template.column_type,
        content: template.content,
      };

      this.currentExpandedContent = expanded_item;
      this.expandedContents[item.id] = expanded_item;
    }

    this.expand.emit({row: row});
  }

  expandAll() {
    this.expandedElements = [];
    if (this.data.length) {
      this.data.forEach((element) => {
        // this.expandedElements.push(element.id);
        this.expandRow(element);
      });
      if (this.allColumnExpanded) {
        this.expandedElements = [];
        this.allColumnExpanded = false;
        // this.expand.emit({ expand: true, items: this.data })
      } else {
        this.allColumnExpanded = true;
        // this.expand.emit({ expand: true, items: this.data })
      }
    }
  }

  unExpandAll() {
    this.allColumnExpanded = false;
    this.expandedElements = [];
  }

  isColumnVisible(db_field: string) {
    if (this.displayedDbFields.indexOf(db_field) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  getStorageData() {
    try {
      let val = localStorage.getItem('storage');
      if (val) {
        return JSON.parse(val);
      } else {
        return {};
      }
    } catch (e) {
      return {};
    }
  }

  setColumnVisibility(event, table_column: TableColumn) {
    if (this.withColumnVisibility) {
      let index = _.findIndex(this.touchedColumns, {
        column: table_column.column,
      });
      if (index !== -1) {
        this.touchedColumns[index] = {
          visibility: event.checked,
          column: table_column.column,
        };
      } else {
        this.touchedColumns.push({
          visibility: event.checked,
          column: table_column.column,
        });
      }
    }

    this.setEditableFieldsForms(this.data);
  }

  applyColVisibility() {
    if (this.touchedColumns.length) {
      this.rememberColumnVisibility(this.touchedColumns);

      // set columns
      this.setTableColumns();
    } 
    if(this.visibility_filter_value){
      setTimeout(() => {
        this.visibility_filter_value = '';
        this.visibilityColumnsMenu = this.tableColumns.filter(option => !option.hidden);
      }, 500);
    }
  }

  rememberColumnVisibility(columns) {
    let data = this.getStorageData();

    if (!data[this.table]) {
      data[this.table] = {
        columns_visibility: {},
      };
    } else {
      if (!data[this.table]['columns_visibility']) {
        data[this.table]['columns_visibility'] = {};
      }
    }
    if (columns) {
      columns.forEach((c) => {
        data[this.table]['columns_visibility'][c.column] = c.visibility;
      });
    }
    this.touchedColumns = [];
    try {
      localStorage.setItem('storage', JSON.stringify(data));

      this.s_visibility = data[this.table]['columns_visibility'];
    } catch (e) {
      console.log('Error saving localstorage');
    }
  }

  rememberPageSize() {
    let data = this.getStorageData();

    if (!data[this.table]) {
      data[this.table] = {
        pageSize: this.paginator.pageSize,
      };
    } else {
      if (!data[this.table]['pageSize']) {
        data[this.table]['pageSize'] = '';
      }
      data[this.table]['pageSize'] = this.paginator.pageSize;
    }

    try {
      localStorage.setItem('storage', JSON.stringify(data));
    } catch (e) {
      console.log('Error saving localstorage');
    }
  }

  getProperty(
    propertyName: string | Array<string>,
    object = null,
    parent = null
  ) {
    if (!object) {
      return null;
    }
    let parts =
      typeof propertyName == 'string' ? propertyName.split('.') : propertyName;
    let first = parts.shift();
    if (parts.length > 0) {
      return this.getProperty(parts, object[first]);
    } else {
      return object[first];
    }
  }

  findById(array, id) {
    let o = _.find(array, { item_id: id });
    if (o) {
      return o;
    } else {
      return null;
    }
  }

  objectSize(obj) {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  }

  returnValue(v) {
    if (typeof v === 'undefined') {
      return false;
    } else {
      return true;
    }
  }

  menuItemEvent(event, row = null) {
    let retEvent = null;

    if (row) {
      retEvent = { event: event, item: row };
    } else {
      retEvent = {
        event: event,
        filters: this.filters,
        params: this.params,
        extraParameters: this._extraParameters,
      };
    }

    this.menuEvent.emit(retEvent);
  }

  getMenu(menuName: string) {
    let menu = this._menus[menuName];
    if (menu) {
      return menu;
    } else {
      return [];
    }
  }

  toBoolean(v) {
    if (v == 1) {
      return true;
    } else if (v == 0) {
      return false;
    } else {
      return '';
    }
  }

  resetColumnVisibility() {
    // remove data from storage
    this.s_visibility = {};
    let data = this.getStorageData();
    if (!data[this.table]) {
      data[this.table] = {
        columns_visibility: {},
      };
    } else {
      data[this.table]['columns_visibility'] = {};
    }
    try {
      localStorage.setItem('storage', JSON.stringify(data));
    } catch (e) {
      console.log('Error saving localstorage');
    }

    // reset columns
    this.setTableColumns();
  }

  getFilters(fields, operator) {
    let filter = {
      operator: operator,
      fields: fields,
    };
    return filter;
  }

  rememberColFilters(column, value) {
    let data = this.getStorageData();

    if (!data[this.table]) {
      data[this.table] = {
        columns_filters: {},
      };
    } else {
      if (!data[this.table]['columns_filters']) {
        data[this.table]['columns_filters'] = {};
      }
    }
    data[this.table]['columns_filters'][column] = value;
    try {
      localStorage.setItem('storage', JSON.stringify(data));

      this.storageColFilters = data[this.table]['columns_filters'];
    } catch (e) {
      console.log('Error saving localstorage');
    }
  }

  clearStorageColFilters(column = null, type = null) {
    let data = this.getStorageData();
    let storageColFilters = this.storageColFilters;
    if (data[this.table]) {
      if (data[this.table]['columns_filters']) {
        if (column) {
          if (
            typeof data[this.table]['columns_filters'][column] !== 'undefined'
          ) {
            delete data[this.table]['columns_filters'][column];
          }
        } else {
          if (type) {
            if (type === 'global_filter') {
              delete data[this.table]['columns_filters']['global_filter'];
            } else if (type === 'col_filter') {
              for (let cf in data[this.table]['columns_filters']) {
                if (cf !== 'global_filter') {
                  delete data[this.table]['columns_filters'][cf];
                }
              }
            }
          } else {
            delete data[this.table]['columns_filters'];
          }
        }
        storageColFilters = data[this.table]['columns_filters'];
      } else {
        storageColFilters = {};
      }
    } else {
      storageColFilters = {};
    }

    try {
      localStorage.setItem('storage', JSON.stringify(data));

      this.storageColFilters = storageColFilters;
    } catch (e) {
      console.log('Error saving localstorage');
    }
  }

  rememberColSort() {
    let data = this.getStorageData();

    if (!data[this.table]) {
      data[this.table] = {
        sort: {},
      };
    } else {
      if (!data[this.table]['sort']) {
        data[this.table]['sort'] = {};
      }
    }
    data[this.table]['sort']['column'] = this.sort.active;
    data[this.table]['sort']['dir'] = this.sort.direction;

    try {
      localStorage.setItem('storage', JSON.stringify(data));

      this.storageColSort = data[this.table]['sort'];
    } catch (e) {
      console.log('Error saving localstorage');
    }
  }

  clearColSort() {
    let data = this.getStorageData();

    if (data[this.table]) {
      delete data[this.table]['sort'];
    }
    try {
      localStorage.setItem('storage', JSON.stringify(data));

      this.storageColSort = {};
    } catch (e) {
      console.log('Error saving localstorage');
    }
  }

  rememberColSizes(column, value) {
    let data = this.getStorageData();

    if (!data[this.table]) {
      data[this.table] = {
        columns_sizes: {},
      };
    } else {
      if (!data[this.table]['columns_sizes']) {
        data[this.table]['columns_sizes'] = {};
      }
    }
    data[this.table]['columns_sizes'][column] = value;
    try {
      localStorage.setItem('storage', JSON.stringify(data));

      this.storageColSizes = data[this.table]['columns_sizes'];
    } catch (e) {
      console.log('Error saving localstorage');
    }
  }

  clearColSizes() {
    let data = this.getStorageData();

    if (data[this.table]) {
      delete data[this.table]['columns_sizes'];
    }
    try {
      localStorage.setItem('storage', JSON.stringify(data));

      this.storageColSizes = {};
      this.fixSticky();
    } catch (e) {
      console.log('Error saving localstorage');
    }
  }

  clearPaginator() {
    let data = this.getStorageData();

    if (data[this.table]) {
      data[this.table]['pageSize'] = this.default_page_size;
    }
    try {
      localStorage.setItem('storage', JSON.stringify(data));
      this.paginator_pageSize = this.default_page_size;
      this.paginator.pageSize = this.default_page_size;
    } catch (e) {
      console.log('Error saving localstorage');
    }
  }

  clearStorage() {
    let data = {};
    try {
      localStorage.setItem('storage', JSON.stringify(data));

      this.storageColSort = {};
    } catch (e) {
      console.log('Error saving localstorage');
    }
  }

  rememberStorageTimeout() {
    let data = this.getStorageData();
    data['filters_timeout'] = this.toTimestamp(new Date());

    try {
      localStorage.setItem('storage', JSON.stringify(data));
    } catch (e) {
      console.log('Error saving localstorage');
    }
  }

  toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  trackByIndex(i) {
    return i;
  }

  findIndexInData(id) {
    let index = _.findIndex(this.data, {id: id});
    return index;
  }

  onCellClickEvent(event, row, col) {
    if(col.filterOnDoubleClick) {
      return
    }
    if(col.clickEvent) {
      col.onClick(event, row, this.findIndexInData(row.id));
    } else if(this.withCellClick) {
      this.onCellClick.emit({row: row, col: col, row_index: this.findIndexInData(row.id)});
    } else {
      if (this.expandable && this.expandOnRowClick) {
        this.expandRow(row);
      }
    }
  }

  public tableCheckedRows = {};
  public ckRowsData = [];
  public allRowsChecked = false;
  onCheckAllRows(event) {
    this.tableCheckedRows = {};
    if(event.checked) {
      if (this.data.length) {
        this.data.forEach((element) => {
          this.tableCheckedRows[element.id] = true;
        });
      }
    }
    this.setCheckedRows();
  }

  onCheckRow(event) {
    // console.log(this.tableCheckedRows);
    this.allRowsChecked = false;
    setTimeout(() => {
      this.setCheckedRows();
    })
  }

  setCheckedRows() {
    this.ckRowsData = [];
    for(let ckrow in this.tableCheckedRows) {
      if(this.tableCheckedRows[ckrow]) {
        let o = _.find(this.data, {id: Number(ckrow)});
        if(o) {
          this.ckRowsData.push(o);
        }
      }
    }
    this.checkedRows.emit({ckRowsData: this.ckRowsData});
  }

  selectionChange(filterValue: string, column: TableColumn, event = null) {
    this.applyColFilter(filterValue, column, event);
    setTimeout(() => {
      this.onColFilter();
    })
  }

  private _multiSelectTimeout = null;
  multiSelectionChange(filterValue: any[], column: TableColumn, event = null) { 
    let fValue = '';
    if(filterValue && filterValue.length) {
      fValue = filterValue.reduce((acc, val) => acc + Number(val), 0);
      fValue = fValue.toString();
      this.filter_db_fields_values[column.column] = fValue;
    } else { // no option selected
      let defaultSelectedOptions = [];
      const filterOptions = column.filter_options ?? [];
      filterOptions.forEach(option => {
        if(option.selected) {
          defaultSelectedOptions.push(option.value);
        }
      })
      // set default selected options
      if(defaultSelectedOptions.length) {
        this.filter_db_fields_values[column.column] = defaultSelectedOptions.reduce((acc, val) => acc + Number(val), 0);
      } else {
        this.filter_db_fields_values[column.column] = null;
      }
    }
    this.applyColFilter(fValue, column, event);

    if (this._multiSelectTimeout) {
      clearTimeout(this._multiSelectTimeout);
    }
    this._multiSelectTimeout = setTimeout(() => {
      this.onColFilter();
    }, 1000)
  }

  setMultiSelectDefaultValue() {
    let multiSelectColumns = _.filter(
      this.tableColumns, function (o) {
        return o.filter_type == 'multi-select';
      }
    );
    if(multiSelectColumns) {
      multiSelectColumns.forEach(o => {
        // multi-select value
        if(o.filter_options) {
          let defaultSelectedOptions = [];
          o.filter_options.forEach(option => {
            if(option.selected) {
              defaultSelectedOptions.push(option.value);
            }
          })
          if(defaultSelectedOptions.length && !this.filter_db_fields[o.db_field]) { debugger
            const defaultValue = defaultSelectedOptions.reduce((acc, val) => acc + Number(val), 0);
            this.filter_db_fields[o.db_field] = defaultValue;
            this.filter_db_fields_array_values[o.db_field] = defaultSelectedOptions;
          }
        }
      });
    }
  }

  formatMultiSelectValue(v) {
    return `in*(${v.map(value => `"${value}"`).join(',')})`
  }

  transformInStringToArray(inString: string): string[] { // input = "in*("value1","value2",...)""
    // remove string in*()
    let trimmedString = inString.slice(4, -1);
    // split the string by '","'
    let array = trimmedString.split('","');
    // remove ""
    return array.map(item => item.replace(/"/g, ''));
  }

  rebuildRouteWithFilters(filters, controls, pathChange) {
    let fragment = "#";
    for(let f in filters) {
      let setFragment: boolean = true;
      const db_field = f;
      const value = filters[f];
      let o = _.find(this.tableColumns, {db_field: db_field});
      if(o) {
        // multi-select value
        if(o.filter_type == 'multi-select' && o.filter_options) {
          // setFragment = false;
          let defaultSelectedOptions = [];
          o.filter_options.forEach(option => {
            if(option.selected) {
              defaultSelectedOptions.push(option.value);
            }
          })
          if(defaultSelectedOptions.length) { 
            const defaultValue = defaultSelectedOptions.reduce((acc, val) => acc + Number(val), 0);
            // don't set the fragment if default value selected
            if(defaultValue == value) {
              setFragment = false;
            }
          }
        }
        if(setFragment) {
          const column = o.column;
          fragment+=`${column}=${value};`
        }
      } else if(db_field == 'search') { //global filter
        fragment+=`search=${value};`
      }
    }
    for(let c in controls) {
      if(c == 'sort') {
        let o = _.find(this.tableColumns, {db_field: controls[c]});
        if(!o) {
          o = _.find(this.tableColumns, {column: controls[c]});
        }
        if(o) {
          fragment+=`${c}=${o.column};`
        }
      } else {
        fragment+=`${c}=${controls[c]};`
      }
    }

    if(fragment.length > 1) {
      this._routeFilters = decodeURIComponent(fragment.slice(1));
    } else {
      this._routeFilters = null;
    }

    let currentLocation = this.location.path(false);
    if(!pathChange || (this.buildRouteFilters && this._routeFilters && currentLocation.includes('/bookmark/'))) {
      return
    }
    if(fragment.length > 1) {
      const pathWithoutHash = this.location.path(false);
      this.location.replaceState(pathWithoutHash+fragment);
    } else {
      const pathWithoutHash = this.location.path(false);
      this.location.replaceState(pathWithoutHash);
    }
  }

  onCellDoubleClickEvent(event, row, col) {
    if (col.filterOnDoubleClick && !col.isEditableField(col,row)) {
      event.preventDefault();

      if (this.filter_db_fields_values[col.db_field] && this.filter_db_fields_values[col.db_field] == row[col.column]) {
        // cancel the filter
        delete this.filter_db_fields_values[col.db_field];
        delete this.filter_db_fields[col.db_field];
        this.clearStorageColFilters(col.db_field);
        this.onColFilter();
      } else {
        // putting the value in the filter and load data 
        if (this.filter_db_fields_values[col.db_field] && this.filter_db_fields_values[col.db_field] != row[col.column] ||  !this.filter_db_fields_values[col.db_field]) {
          this.filter_db_fields_values[col.db_field] = row[col.column];
          this.onColFilter();
        }
      }
    } if(col.isEditableField(col,row) && col.editableField) {
      event.preventDefault();
      this.onTdDbClick(event,col,row, this.editableFieldsForms['form_'+row.id])
    }
  }

  setFiltersValuesForRequest(filters) {
    filters.forEach(filter => {
      let fields = filter.fields;
      for(let f in fields) {
        let o = _.find(this.tableColumns,{db_field: f});
        // if type = boolean, use '=' operator 
        if(o && o.type == 'boolean' ) {
          fields[f] = "^" + fields[f] + "$";
        }
      }
    })
    return filters;
  }

  public currentSelectedCol = null;
  public tabPressed: boolean = false;
  public enterPressed: boolean = false;
  onTdDbClick(event, col, row, form) {
    event.stopPropagation();
    this.setCurrentSelectedCol(col, row);
    if(form) {
      let form_control = form.controls[col.column];
      if(form_control) {
        form_control.setValue(this.getProperty(col.column,row,true));
      }
    }
  }

  editableFieldId(col, row) {
    let column = col.column.replace(/\./g,'_');
    let id = column+'_'+row.id+'_field';
    return id;
  }

  setCurrentSelectedCol(col, row) {
    this.currentSelectedCol = null;

    if(col.isEditableField(col,row) && col.editableField) {
      this.currentSelectedCol = this.editableFieldId(col, row);
      setTimeout(() => {
        let input = this.elRef.nativeElement.querySelector(`#${this.currentSelectedCol}`);
        if (input) {
          (<HTMLElement>input).focus();
        }
      })
    }
  }

  setEditableFieldsForms(data) {
    this.editableFieldsForms = {};
    data.forEach(d => {
      let editableFieldsData = {};
      let form = new UntypedFormGroup({},{
        updateOn: 'change'
      });

      this.tableColumns.forEach((col) => {
        if(col.isEditableField(col, d) && col.editableField && this.isColumnVisible(col.db_field)) {
          editableFieldsData[col.column] = this.getProperty(col.column,d,true);
          form.addControl(col.column, new UntypedFormControl(this.getProperty(col.column,d,true), col.editableField.validators));
        }
      });
      if(Object.keys(editableFieldsData).length) {
        form.addControl('id', new UntypedFormControl(d.id, [Validators.required]));
        this.editableFieldsForms['form_'+d.id] = form;
      }
    });
  }

  onEditableFieldEnter(col, row, form) {
    this.enterPressed = true;
    this.currentSelectedCol = null;
    let controlName = col.column;
    if(form.controls[controlName]) {
      form.controls[controlName].markAsTouched();
      form.controls[controlName].updateValueAndValidity({ emitEvent: false });
    }
    if(col.isEditableField(col, row) && col.editableField) {
      this.onsaveByRow(form, col.column, row);
    }
  }

  onEditableFieldTab(col, row, form, event) {
    let self = this;
    event.preventDefault();
    this.tabPressed = true;
    this.currentSelectedCol = null;
    let index = _.findIndex(this.tableColumns,{column: col.column});

    // set column value
    if(form.valid) {
      this.setColNewVal(row, col.column, form);
    }
    if(index !== -1) {
      let nextIndex = index+1;
      let nextCol = this.tableColumns[nextIndex];
      if(nextCol && nextCol.column) {
        if(nextCol.isEditableField(nextCol, row) && nextCol.editableField && this.isColumnVisible(nextCol.db_field)) {
          return this.setCurrentSelectedCol(nextCol, row);
        } else {
          return this.onEditableFieldTab(nextCol, row, form, event);
        }
      } else { // if last col, then save and go to next row
        this.onsaveByRow(form, col.column, row);
        let row_index = _.findIndex(this.data, {id: row.id});
        if(row_index !== -1) {
          let next_row = this.data[row_index + 1];
          if(next_row) {
            let nextRowCol = _.find(this.tableColumns, function (x) {
              return x.isEditableField(x, row) && x.editableField && self.isColumnVisible(x.db_field);
            })
            if(nextRowCol) {
              return this.setCurrentSelectedCol(nextRowCol, next_row);
            }
          }
        }
        // save
        return this.onsaveByRow(form, col.column, row);
      }
    }
  }

  onEditableFieldFocusOut(col, row, form) {
    if(!this.enterPressed && !this.tabPressed) {
      this.currentSelectedCol = null;
      if(col.isEditableField(col, row) && col.editableField) {
        this.onsaveByRow(form, col.column, row);
      }
    }

    this.enterPressed = false;
    this.tabPressed = false;
  }

  onEditableFieldInput(col, row, form) {
    // if(form.valid) {
      this.setColNewVal(row, col.column, form)
      this.editableFieldInput.emit({column_name: col.column, column: col, row: row, form: form});
    // }
  }

  onsaveByRow(form, column, row) {
    // console.log("onsaveByRow", form.dirty)
    if(form.valid) {
      if(form.dirty) {
        this.setColNewVal(row, column, form);
        this.saveByRow.emit({form: form, column: column});
        // console.log("=====save");
      } else {
        // console.log("=====form is not dirty");
      }
    } else {
      console.log("Form not valid!")
    }
    form.markAsPristine();
  }

  setColNewVal(row, column, form, new_val = null) {
    let new_val_control = form.controls[column];
    let r = this.getRowProperty(column, row, true);
    if(typeof new_val_control !== 'undefined') {
      if(r) {
        r.object[r.key] = new_val_control.value;
      }
    } else {
      if(r) {
        r.object[r.key] = new_val;
      }
    }
  }

  hasFieldError(form: UntypedFormGroup, controlName: string, errorName: string) {
    if (!controlName) {
      return form.hasError(errorName);
    }
    return form.controls[controlName].hasError(errorName);
  }

  updateRowById(row) {
    let index = _.findIndex(this.data, {id: row.id});
    if(index !== -1) {
      this.data[index] = row;
    }
    setTimeout(() => {
      this.updateSticky.renderRows()
    })
  }

  getRowProperty(
    propertyName: string | Array<string>,
    object = null,
    parent = null
  ) {
    if (!object) {
      return null;
    }
    let parts =
      typeof propertyName == 'string' ? propertyName.split('.') : propertyName;
    let first = parts.shift();
    if (parts.length > 0) {
      return this.getRowProperty(parts, object[first]);
    } else {
      return {
        object: object,
        key: first
      }
    }
  }

  onRowDoubleClickEvent(event, row) {
    this.onRowDbClick.emit(row);
  }

  clearCheckedRows() {
    if(this.checkRow) {
      this.tableCheckedRows = {}
    }
  }

  fixSticky() {
    // update sticky column styles
    setTimeout(() => {
      if(this.updateSticky){
          this.updateSticky.updateStickyColumnStyles();
          this.updateSticky.updateStickyHeaderRowStyles();
        }
    }, 500);
  }

  public initialX: number;
  public initialWidth: number;
  public resizing: boolean = false;
  public currentResizedColumn: any = null;

  onResizeStart(event: MouseEvent, column: any) {
    this.initialX = event.clientX;
    this.initialWidth = column.width;
    this.resizeColumn(event, column)
  }

  resizeColumn(event: any, column: any) {
    this.resizing = true;
    this.currentResizedColumn = column
    this.renderer.listen('document', 'mousemove', (event) => {
      // const offs = 35;
      if (this.resizing && this.currentResizedColumn) {
        const offset = this.initialWidth + (event.clientX - this.initialX);
 
        // Applying min and max width constraints
        const newWidth = Math.max(this.currentResizedColumn.minWidth || 0, Math.min(this.currentResizedColumn.maxWidth || Number.POSITIVE_INFINITY, offset));
        // column.width = newWidth;

        // remembre column size
        this.rememberColSizes(this.currentResizedColumn.db_field, newWidth);
        this.fixSticky();
      }
    });
  
    this.renderer.listen('document', 'mouseup', (event) => {
      this.resizing = false;
      this.currentResizedColumn = null;
    });
  }

  visibilityMenuOpened() {
    // Focus on the input element when the menu is opened
    setTimeout(() => {
      if (this.visibilityFilterInput) {
        this.visibilityFilterInput.nativeElement.focus();
      }
    },150);
  }

  onIconClick(event, col, row, value) {
    event.stopPropagation();
    col.onIconClick(value, row)
  }

  setMultiseleftFilterOptions(options) {
    let exponentialOptions = [];
    options.forEach((o, index) => {
      o.value = Math.pow(2,(index));
      exponentialOptions.push(o);
    })
    return exponentialOptions;
  }

  decodePowersOfTwo(value: number): number[] {
    const powers: number[] = [];
    let i = 0;
    while (value > 0) {
      if (value & 1) {
        powers.push(Math.pow(2, i));
      }
      value >>= 1;
      i++;
    }
    return powers;
  }
}
